import React, { ReactElement, Suspense } from 'react'

import SearchResultsLayout from '@pages/SearchResult/Layout'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

import '@pages/SearchResult/App.scss'

const App = (): ReactElement => {
  const [{ weekly }] = useParams()
  const [{ weeklyTrip }] = useSettings()

  const showWeeklyTrips = weeklyTrip.enabled && !!weekly

  return (
    <Suspense>
      <SearchResultsLayout header footer trips={!showWeeklyTrips} weeklyTrips={showWeeklyTrips} />
    </Suspense>
  )
}

export default App

import { Field } from 'formik'
import React from 'react'

import { useTranslation } from '@lib/i18n'
import { required } from '@lib/validators'
import InputField from '@ui/Input/Field'

const LostItemInformation = () => {
  const { t } = useTranslation()

  return (
    <div className="row gap-3 wrap">
      <div className="cell-6 cell-sm-12">
        <Field
          component={InputField}
          label={t('customBlocksCommon.form.lostObject')}
          name="lostObject"
          validate={required}
          required
        />
      </div>
      <div className="cell-6 cell-sm-12">
        <Field
          component={InputField}
          label={t('customBlocksCommon.form.lostBrand')}
          name="lostBrand"
          validate={required}
          required
        />
      </div>
      <div className="cell-6 cell-sm-12">
        <Field component={InputField} label={t('customBlocksCommon.form.lostModel')} name="lostModel" />
      </div>
      <div className="cell-6 cell-sm-12">
        <Field
          component={InputField}
          label={t('customBlocksCommon.form.lostColor')}
          name="lostColor"
          validate={required}
          required
        />
      </div>
    </div>
  )
}

export default LostItemInformation

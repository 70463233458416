import React, { ReactElement } from 'react'

import Connection from '@components/Connection'
import TripInfo from '@components/TripInfo'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import weeklyUtils from '@lib/weekly'

import '@pages/Checkout/BookingDetails/TripDetails/ConnectionDetails/index.scss'

interface ConnectionDetailsProps {
  connection: Connection
  type: ConnectionType
  fareClass: string
  carriers: MarketingCarrier[]
  hideTime?: boolean
  weeklyConnections?: WeeklyConnectionsParams | null
}

const ConnectionDetails = (props: ConnectionDetailsProps): ReactElement => {
  const { connection, type, fareClass, carriers, hideTime, weeklyConnections } = props
  const { t } = useTranslation()
  const count = weeklyUtils.getConnectionsCount(weeklyConnections)
  const dates = weeklyUtils.getDatesRange(weeklyConnections)

  const getTitle = (): string | null => {
    if (count) return t('checkout.bookingDetails.weeklyTrip', { count })
    if (type === 'outbound') return t('checkout.bookingDetails.outboundTrip')

    return t('checkout.bookingDetails.returnTrip')
  }

  return (
    <>
      <h4 className={bem('trip-connection-details', 'heading')}>{getTitle()}</h4>
      {dates && <div className="mb-2">{dates}</div>}
      {!hideTime && (
        <div className={bem('trip-connection-details', 'subheading')}>
          <Connection.DepartureDate connection={connection} />
          {' – '}
          <Connection.ArrivalDate connection={connection} />
        </div>
      )}
      <TripInfo
        connection={connection}
        fareClass={fareClass}
        carriers={carriers}
        type={type}
        hideTime={hideTime}
        weeklyConnections={weeklyConnections}
      />
    </>
  )
}

export default ConnectionDetails

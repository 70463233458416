import { useMemo } from 'react'
import { useQueryClient } from 'react-query'

import useSearchBarSettings from '@components/SearchBar/useSearchBarSettings'
import useSearchFormInitial, { SearchParams } from '@components/SearchBar/useSearchFormInitial'
import { SearchFormData, SearchFormSettings } from '@components/SearchForm'
import useSearchParams from '@hooks/useSearchParams'
import paramsUtils from '@lib/params'
import passengersUtils from '@lib/passengers'
import qs from '@lib/queryString'
import utils from '@lib/utils'
import weeklyUtils from '@lib/weekly'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

interface UseSearchBarResult {
  submit: (values: SearchFormData) => void
  initialValues: SearchFormData
  settings: SearchFormSettings
}

const isSameParams = (a: any, b: any): boolean => {
  const sort = (a: string, b: string): number => String(a)?.localeCompare(String(b))
  return qs.stringify(a, { sort }) === qs.stringify(b, { sort })
}

const useSearchForm = (paramsOverride: Partial<SearchParams> = {}): UseSearchBarResult => {
  const [settings] = useSettings()
  const [params] = useParams()
  const [queryParams, setQueryParams] = useSearchParams()
  const searchParams = useMemo((): SearchParams => ({ ...params, ...paramsOverride }), [params, paramsOverride])
  const initialValues = useSearchFormInitial(searchParams)

  const { passengerTypesList, discountCodes } = settings

  const queryClient = useQueryClient()

  const submit = (values: SearchFormData): void => {
    const { departureLocation, arrivalLocation, pax, passengers, cards, ...rest } = values
    const {
      departureCity: _departureCity,
      departureArea: _departureArea,
      departureStation: _departureStation,
      arrivalCity: _arrivalCity,
      arrivalArea: _arrivalArea,
      arrivalStation: _arrivalStation,
      ...restQuery
    } = queryParams

    const requestParams = utils.object.compact({
      ...restQuery,
      ...(departureLocation && paramsUtils.flatLocation(departureLocation, 'departure')),
      ...(arrivalLocation && paramsUtils.flatLocation(arrivalLocation, 'arrival')),
      ...rest,
      pax: passengerTypesList.enabled ? passengersUtils.getPaxCount(passengers) : pax,
      passengers: passengerTypesList.enabled ? passengers : null,
      cards: discountCodes.enabled && Number(cards?.length) > 0 ? cards : null,
      weekly: values.weekly,
      marketingCarrierCode: weeklyUtils.getCarrierCode(
        params.retailerPartnerNumber,
        params.marketingCarrierCode,
        values.weekly,
      ),
    })

    queryClient.removeQueries({ queryKey: ['connections'], inactive: true })
    if (isSameParams(requestParams, queryParams)) {
      queryClient.refetchQueries({ queryKey: ['connections'], active: true })
    }

    setQueryParams(requestParams)
  }

  const formSettings = useSearchBarSettings()

  return { submit, initialValues, settings: formSettings }
}

export default useSearchForm

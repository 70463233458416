import makeApi from '@lib/api'

interface PaymentTerminalLoadRequest {
  marketingCarrierCode: string
  retailerPartnerNumber: number
  paymentProvider: PaymentProvider
}

export interface PaymentTerminalData {
  id: string
  status: 'active' | 'inactive'
}

type PaymentTerminalLoadResponse = PaymentTerminalData[]

const portalApi = {
  paymentTerminals: {
    load: makeApi.get<PaymentTerminalLoadRequest, PaymentTerminalLoadResponse>(`/payment_terminals`),
  },
}

export default portalApi

import { useQuery } from '@tanstack/react-query'

import portalApi from '@api/portal'
import { useAppContext } from '@context/app'
import { useSettings } from '@queries/settings'

export const usePaymentTerminals = () => {
  const { retailerPartnerNumber, marketingCarrierCode } = useAppContext()
  const [{ paymentProvider }] = useSettings()

  return useQuery({
    queryKey: ['portalSettings', retailerPartnerNumber, marketingCarrierCode, paymentProvider.code],
    enabled: retailerPartnerNumber > 0 && marketingCarrierCode != null,
    queryFn: () =>
      portalApi.paymentTerminals.load({
        retailerPartnerNumber,
        marketingCarrierCode: marketingCarrierCode!,
        paymentProvider: paymentProvider.code,
      }),
    staleTime: Infinity,
    gcTime: Infinity,
  })
}

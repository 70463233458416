import React, { ReactElement } from 'react'

import useSearchParams from '@hooks/useSearchParams'
import amplitude from '@lib/analytics/amplitude'
import localeUtils from '@lib/locale'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Dropdown } from '@ui'

import '@components/LocaleDropdown/index.scss'

const LocaleDropdown = (): ReactElement => {
  const [{ locale: current }] = useParams()
  const [_, setSearchParams] = useSearchParams()
  const [
    {
      locale: { supported },
    },
  ] = useSettings()

  const setLocale = (locale: Locale): void => {
    amplitude.changeLocale({ from: current, to: locale })
    setSearchParams(current => ({ ...current, locale }))
  }

  const languageList = supported.map(locale => {
    const flagImage = localeUtils.getFlagImage(locale)

    return {
      value: locale,
      label: (
        <div className="locale-list__item">
          <div className="item__image-wrapper">
            <img className="locale-list__image" src={flagImage} alt={locale} />
          </div>
          <div className="item__name">{localeUtils.getName(locale)}</div>
        </div>
      ),
      selectedLabel: <img className="locale-list__image" src={flagImage} alt={locale} />,
    }
  })

  return (
    <Dropdown
      items={languageList}
      value={current}
      onChange={setLocale}
      className="locale-list"
      checkedMark
      popperProps={{ anchorWidth: false, maxHeight: 'md' }}
    />
  )
}

export default LocaleDropdown

import cn from 'classnames'
import { useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'

import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { required } from '@lib/validators'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useDiscounts } from '@queries/dicounts'
import { useParams } from '@stores/params'
import { Button, Icon, Input } from '@ui'

import '@pages/Checkout/BookingDetails/Voucher/index.scss'

enum VoucherStatus {
  Closed,
  Edit,
  Applied,
}

const Voucher = () => {
  const { t } = useTranslation()
  const [{ mode }] = useParams()
  const [status, setStatus] = useState<VoucherStatus>(() =>
    mode === 'embed' ? VoucherStatus.Edit : VoucherStatus.Closed,
  )
  const [voucherCode, setVoucherCode] = useState<string>('')
  const [error, setError] = useState<string | undefined>(undefined)
  const {
    setFieldValue,
    values: { isReservationLoading, contact, meta },
  } = useFormikContext<CheckoutFormData>()

  const discounts = useDiscounts({ enabled: true }, ['voucher'])
  const paxDiscount = discounts.data?.find(({ context }) => context === 'pax')

  const isValidating = isReservationLoading || discounts.isLoading
  const changeVoucherCode = (value: string) => {
    setVoucherCode(value)
    setError(undefined)
  }

  const removeVoucher = () => {
    setStatus(VoucherStatus.Edit)
    setFieldValue('meta.voucher', [])
    changeVoucherCode('')
  }

  useEffect(() => {
    if (status === VoucherStatus.Applied && !meta.voucher.length) removeVoucher()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.voucher])

  const toggleVoucher = () => {
    /* istanbul ignore next */
    if (isValidating) return
    setStatus(status => (status === VoucherStatus.Closed ? VoucherStatus.Edit : VoucherStatus.Closed))
    changeVoucherCode('')
  }

  const getErrorMessage = (): string | null | undefined => {
    if (!contact.email) return t('checkout.voucher.email')
    if (!paxDiscount) return t('checkout.voucher.invalid')
    if (!voucherCode) return required(voucherCode)

    return null
  }

  const applyVoucher = () => {
    const error = getErrorMessage()

    if (error) {
      setError(error)
      return
    }

    setFieldValue('meta.voucher', [{ name: paxDiscount?.name, code: voucherCode }])
    setStatus(VoucherStatus.Applied)
  }

  if (status === VoucherStatus.Applied) {
    return (
      <div className="row space-between items-center voucher">
        <div className={cn(bem('voucher', 'applied'), 'body-12')}>
          {t('checkout.voucher.appliedNumber', { number: voucherCode })}
        </div>
        <Button variant="text" onClick={removeVoucher}>
          <div className="row items-center">
            <Icon size="medium" name="minus" />
            <span>{t('checkout.voucher.remove')}</span>
          </div>
        </Button>
      </div>
    )
  }

  return (
    <div className={cn('column', 'voucher')}>
      <div className={cn(bem('voucher', 'empty'), 'body-12')} onClick={toggleVoucher}>
        {t('checkout.voucher.empty')}
      </div>
      {status === VoucherStatus.Edit && (
        <div className={cn('row', 'pt-2')}>
          <div className="cell mr-3">
            <Input
              errorMessage={error}
              label={t('checkout.voucher.numberLabel')}
              value={voucherCode}
              onChange={changeVoucherCode}
              disabled={isValidating}
            />
          </div>
          <div className={cn('cell no-grow', bem('voucher', 'apply'))}>
            <Button onClick={applyVoucher} variant="text" loading={isValidating}>
              {t('checkout.voucher.apply')}
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Voucher

import amendmentAPI, { AmendmentStatusResponse } from '@api/amendments'
import bookingAPI, { BookingStatusResponse } from '@api/booking'
import reservationAPI, { StatusResponse as ReservationStatusResponse } from '@api/reservation'
import config from '@config'
import { makeMutation } from '@lib/mutation'
import polling, { PollingApiError } from '@lib/polling'
import bookingStore from '@stores/booking'
import settingsStore from '@stores/settings'

type StatusResponse = BookingStatusResponse | ReservationStatusResponse | AmendmentStatusResponse

const getApi = (isAmendment: boolean, isReservation: boolean) => {
  if (isAmendment) return amendmentAPI.status

  return isReservation ? reservationAPI.status : bookingAPI.status
}

export const useBookingStatusPolling = makeMutation<number, StatusResponse, PollingApiError>({
  key: 'bookingStatusPolling',
  loader: async id => {
    const settings = settingsStore.get()
    const booking = bookingStore.get()
    const isAmendment = booking.isAmendment
    const isReservation = settings.reservation.enabled || settings.quickReservation.enabled

    const fn = getApi(isAmendment, isReservation)
    const callPolling = await polling<number, StatusResponse>({
      fn: fn,
      validate: ({ bookingStatus }) => bookingStatus === 'success',
      interval: config.bookingStatus.timeout,
      maxAttempts: config.bookingStatus.maxAttempts,
    })

    const data = await callPolling(id)

    // ToDo: we should receive receiptNumber from a new endpoint which will be implemented soon
    localStorage.setItem('lastReceipt', data.receiptNumber)

    return data
  },
})

import { Field, FieldProps, useFormikContext } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import PassengersDropdown from '@components/PassengersDropdown'
import config from '@config'
import amplitude from '@lib/analytics/amplitude'
import { useTranslation } from '@lib/i18n'
import passengerUtils from '@lib/passengers'
import { passengerType } from '@lib/validators'
import DirectionField from '@pages/Checkout/express/DirectionField'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useCheckout } from '@stores/checkout'
import { useParams } from '@stores/params'
import { Skeleton } from '@ui'

const TicketDetails = (): ReactElement => {
  const { t } = useTranslation()
  const [{ outbound }] = useCheckout()
  const [{ express, retailerPartnerNumber }] = useParams()
  const {
    values: { passengers },
    setFieldValue,
  } = useFormikContext<CheckoutFormData>()
  const isReturnAvailable = outbound?.fares.some(({ fareClass }) => fareClass.journeyType === 'open_return')
  const passengerList = useMemo(() => passengerUtils.getPassengerList(passengers), [passengers])
  const isLoading = !outbound

  const isPassengerIncreased = (value: Passenger.Param[]): boolean =>
    passengerUtils.getPaxCount(value) - passengerUtils.getPaxCount(passengers) > 0

  const handleChange = (value: Passenger.Param[]): void => {
    isPassengerIncreased(value) ? amplitude.checkout.addPassenger(express) : amplitude.checkout.removePassenger(express)

    setFieldValue('passengers', value)
  }

  const handleMaxCount = (totalCount: number): number =>
    totalCount >= config.expressMaxPassengers ? 0 : config.expressMaxPassengers
  const handlePassengerValidation = (): void => {
    passengerType(passengerList, retailerPartnerNumber)
  }
  const passengersError = passengerType(passengerList, retailerPartnerNumber)
  const sortedPassengers = useMemo(() => {
    if (!outbound) return passengers

    const types = outbound.marketingCarrier.passengerTypes

    return passengerUtils
      .sortPassengers(types)
      .map(({ code }) => passengers.filter(({ type }) => code === type))
      .flat()
  }, [outbound, passengers])

  return (
    <div>
      <h3>{t('checkout.express.ticketDetails')}</h3>
      <Skeleton.List Skeleton={() => <Skeleton.Item height={72} />} loading={isLoading} amount={1}>
        <>
          <div className="column row-lg gap-3">
            <div className="cell">
              <div className="column gap-2">
                <b>{t('checkout.express.numberOfPassengers')}</b>
                <Field name="passengers" validate={handlePassengerValidation}>
                  {({ form: { errors } }: FieldProps) => (
                    <PassengersDropdown
                      label={t('searchBar.passengers.label')}
                      value={sortedPassengers}
                      onChange={handleChange}
                      availableTypes={outbound?.marketingCarrier.passengerTypes}
                      errors={{ ...errors, passengers: passengersError }}
                      getMaxCount={handleMaxCount}
                    />
                  )}
                </Field>
              </div>
            </div>
            {isReturnAvailable && (
              <div className="cell center gap-2 column">
                <b>{t('checkout.express.ticketType')}</b>
                <div className="cell grow row items-center">
                  <DirectionField />
                </div>
              </div>
            )}
          </div>
        </>
      </Skeleton.List>
    </div>
  )
}

export default TicketDetails

import React, { ReactElement } from 'react'

import { Skeleton } from '@ui'

const TimeSkeleton = (): ReactElement => (
  <div className="row items-center center h-100">
    <Skeleton.Item height={20} width={100} />
  </div>
)

export default TimeSkeleton

import { Field } from 'formik'
import React from 'react'

import { useTranslation } from '@lib/i18n'
import { chain, fiscalCode, required } from '@lib/validators'
import InputField from '@ui/Input/Field'

const IndividualInvoiceFields = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className="cell-12 cell-md-6 cell-lg-6">
        <Field
          name="invoice.firstName"
          label={t('checkout.invoice.firstName')}
          component={InputField}
          validate={required}
          required
        />
      </div>
      <div className="cell-12 cell-md-6 cell-lg-6">
        <Field
          name="invoice.lastName"
          label={t('checkout.invoice.lastName')}
          component={InputField}
          validate={required}
          required
        />
      </div>
      <div className="cell-12 cell-md-6 cell-lg-6">
        <Field
          name="invoice.fiscalCode"
          label={t('checkout.invoice.fiscalCode')}
          component={InputField}
          validate={chain([required, fiscalCode])}
          required
        />
      </div>
      <div className="cell-6 hidden block-md block-lg" />
    </>
  )
}

export default IndividualInvoiceFields

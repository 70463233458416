import amendmentAPI, { AmendmentFinalizeRequest, AmendmentFinalizeResponse } from '@api/amendments'
import bookingAPI, { BookingCreateResponse, BookingFinalizeRequest } from '@api/booking'
import reservationAPI, {
  FinalizeRequest as ReservationFinalizeRequest,
  FinalizeResponse as ReservationFinalizeResponse,
} from '@api/reservation'
import { makeMutation } from '@lib/mutation'
import bookingStore from '@stores/booking'
import settingsStore from '@stores/settings'

interface BookingFinalizeLoader extends BookingFinalizeRequest {
  bookingFormId: number
}

interface ReservationFinalizeLoader extends ReservationFinalizeRequest {
  bookingFormId: number
}

interface AmendmentFinalizeLoader extends AmendmentFinalizeRequest {
  bookingFormId: number
}

type Response = ReservationFinalizeResponse | BookingCreateResponse | AmendmentFinalizeResponse
type Request = ReservationFinalizeLoader | BookingFinalizeLoader | AmendmentFinalizeLoader

const getApi = (isAmendment: boolean, isReservation: boolean) => {
  if (isAmendment) return amendmentAPI.finalize

  return isReservation ? reservationAPI.finalize : bookingAPI.finalize
}

export const useFinalizeBooking = makeMutation<Request, Response>({
  key: 'bookingFinalize',
  loader: async params => {
    const settings = settingsStore.get()
    const booking = bookingStore.get()
    const isAmendment = booking.isAmendment
    const isReservation = settings.reservation.enabled || settings.quickReservation.enabled

    const fn = getApi(isAmendment, isReservation)
    const { bookingFormId, ...body } = params

    return await fn(bookingFormId, body)
  },
})

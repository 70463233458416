const vatRegexes: Record<string, RegExp> = {
  AT: /^U\d{8}$/,
  BE: /^0?\d{9}$/,
  BG: /^\d{9,10}$/,
  HR: /^\d{11}$/,
  CY: /^\d{8}[A-Z]$/,
  CZ: /^\d{8,10}$/,
  DK: /^\d{8}$/,
  EE: /^\d{9}$/,
  FI: /^\d{8}$/,
  FR: /^[A-HJ-NP-Z]{0,2}\d{9}$/,
  DE: /^\d{9}$/,
  EL: /^\d{9}$/,
  HU: /^\d{8}$/,
  IE: /^\d{7}[A-Z]{1,2}$/,
  IT: /^\d{11}$/,
  LV: /^\d{11}$/,
  LT: /^\d{9}(\d{3})?$/,
  LU: /^\d{8}$/,
  MT: /^\d{8}$/,
  NL: /^\d{9}B\d{2}$/,
  NO: /^\d{9}MVA$/,
  PL: /^\d{10}$/,
  PT: /^\d{9}$/,
  RO: /^\d{10}$/,
  SK: /^\d{10}$/,
  SI: /^\d{8}$/,
  ES: /^[A-Z]?\d{7}[A-Z]?$/,
  SE: /^\d{12}$/,
  CH: /^\d{3}\\.\\d{3}\\.\\d{3}(MWST|TVA|IVA)$/,
  GB: /^\d{9}$/,
}

// Tax ID check for Italy
const fiscalRegex = /^[A-Z]{6}\d{2}[A-EHLMPR-T]\d{2}[A-Z]\d{3}[A-Z]$/

const getVATRegex = (code: string): RegExp => vatRegexes[code]

const getFiscalCodeRegex = (): RegExp => fiscalRegex

const validationUtils = {
  getVATRegex,
  getFiscalCodeRegex,
}

export default validationUtils

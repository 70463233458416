import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import Money from '@components/Money'
import useClickOutside from '@hooks/useClickOutside'
import useIsMobile from '@hooks/useIsMobile'
import useIsTablet from '@hooks/useIsTablet'
import stairs from '@images/extras/stairs.png'
import wheel from '@images/extras/steering-wheel.png'
import wc from '@images/extras/wc.png'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import seat from '@lib/seatSelection'
import utils from '@lib/utils'
import { useParams } from '@stores/params'
import { Button, Icon, Tooltip } from '@ui'

import '@components/SeatSelection/Seat/index.scss'

interface SeatProps {
  coordinates: { x: number; y: number }
  price?: number | null
  priceCategory?: number | null
  discountCategory?: DiscountCard.Item[]
  discountIndex?: number
  label?: string
  selected?: boolean
  disabled: boolean
  onSelect?: () => void
  isDriverSeat?: boolean
  isFirstSeat?: boolean
}

const DISCOUNT_SIGN = '%'

const isInView = (element: HTMLDivElement): boolean => window.innerHeight > element.getBoundingClientRect().top

const Seat = (props: SeatProps): ReactElement => {
  const {
    coordinates,
    price,
    discountIndex,
    priceCategory,
    label,
    onSelect,
    disabled,
    selected,
    isDriverSeat,
    discountCategory,
    isFirstSeat,
  } = props
  const [{ currency }] = useParams()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()
  const isSmallScreen = isMobile || isTablet
  const isVacant = !disabled && !selected
  const [opened, setOpened] = useState<boolean | undefined>(isSmallScreen ? false : undefined)
  const ref = useRef<HTMLDivElement>(null)
  /* istanbul ignore next: unstable scroll behavior in Cypress */
  const scroll = useCallback(() => {
    ref.current && !isInView(ref.current) && ref.current.scrollIntoView({ behavior: 'smooth' })
  }, [])

  useClickOutside(ref, () => {
    isSmallScreen && setOpened(false)
  })
  useEffect(() => {
    if (!isMobile || !isFirstSeat) return
    // workaround for Safari
    const timeout = setTimeout(scroll, 200)

    return () => clearTimeout(timeout)
  }, [isFirstSeat, isMobile, scroll])

  const paid = price != null && priceCategory != null
  const discounted = useMemo(() => !!discountCategory?.length, [discountCategory])
  const iconsCount = useMemo(() => {
    if (!paid) return 0
    if (disabled || selected) return 1

    return priceCategory
  }, [paid, disabled, selected, priceCategory])

  const tooltipContent = useMemo(() => {
    if (isDriverSeat) return null
    if (!price) return null
    if (disabled && isSmallScreen) return null

    return (
      <div className={bem('seat-selection', 'seat-tooltip')}>
        <div>
          {t('seats.seat')}
          {': '}
          <b>{label}</b>
        </div>
        {!!price && (
          <span>
            +<Money fractional={price} currency={currency} />
          </span>
        )}
        {isSmallScreen && (
          <Button
            color="secondary"
            onClick={() => {
              onSelect?.()
              setOpened(!opened)
            }}
          >
            {selected && t('seats.deselect')}
            {!selected && t('seats.select')}
          </Button>
        )}
      </div>
    )
  }, [isDriverSeat, price, disabled, t, label, currency, isSmallScreen, selected, onSelect, opened])

  const handleClick = (): void => {
    if (isSmallScreen && paid) {
      setOpened(!opened)
      return
    }

    onSelect?.()
  }

  if (label === 'WC') {
    return (
      <img
        src={wc}
        alt="WC"
        className={bem('seat-selection', 'seat-wc')}
        style={{
          gridRow: isSmallScreen ? coordinates.x + 1 : coordinates.y + 1,
          gridColumn: isSmallScreen ? coordinates.y + 1 : coordinates.x + 1,
        }}
      />
    )
  }

  if (label === 'ES') {
    return (
      <img
        src={stairs}
        alt="stairs"
        className={bem('seat-selection', 'seat-stairs')}
        style={{
          gridRow: isSmallScreen ? coordinates.x + 1 : coordinates.y,
          gridColumn: isSmallScreen ? coordinates.y : coordinates.x + 1,
        }}
      />
    )
  }

  if (isDriverSeat) {
    return (
      <span className={bem('seat-selection', 'seat-steering-wheel')}>
        <img src={wheel} alt="steering-wheel" />
      </span>
    )
  }

  const styles = {
    gridRow: isSmallScreen ? coordinates.x + 1 : coordinates.y + 1,
    gridColumn: isSmallScreen ? coordinates.y + 1 : coordinates.x + 1,
  }

  return (
    <Tooltip
      action={isSmallScreen ? 'click' : 'hover'}
      position="top"
      className={bem('seat-selection', 'seat-wrapper')}
      popperClassName={bem('seat-selection', 'seat-tooltip-wrapper')}
      content={tooltipContent}
      style={styles}
      opened={opened}
    >
      <div
        ref={ref}
        style={styles}
        className={bem('seat-selection', 'seat', { selected, disabled, paid, discounted })}
        onClick={handleClick}
      >
        <div className={bem('seat-selection', 'seat-armrest', { left: true })} />
        <div className={bem('seat-selection', 'seat-armrest', { right: true })} />
        <div className={bem('seat-selection', 'seat-armrest', { back: true })}>
          {isVacant &&
            utils.common.times(iconsCount, index => (
              <Icon
                className={bem('seat-selection', 'seat-sign')}
                key={index}
                name={seat.getCurrencyIcon(currency)}
                size="small"
              />
            ))}
          {discountCategory?.[0] && (
            <span className={bem('seat-selection', 'seat-discount', { disabled })}>
              <span className={bem('seat-selection', 'seat-discount-sign')}>{DISCOUNT_SIGN}</span>
              <span className={bem('seat-selection', 'seat-discount-index')}>{discountIndex}</span>
            </span>
          )}
          {!isVacant && <span className={bem('seat-selection', 'seat-armrest-label')}>{label}</span>}
        </div>
        {isVacant && <div className={bem('seat-selection', 'seat-label', { selected })}>{label}</div>}
        {disabled && !isDriverSeat && <Icon name="cross" size="large" />}
        {selected && (
          <span className={bem('seat-selection', 'selected-icon')}>
            <Icon name="checkout" size="large" />
          </span>
        )}
      </div>
    </Tooltip>
  )
}

export default Seat

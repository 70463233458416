import { addDays } from 'date-fns'
import React, { ReactElement, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import useCheckoutUrl from '@components/JourneyList/hooks/useCheckoutUrl'
import useConnectionsSearchParams from '@components/JourneyList/hooks/useConnectionsSearchParams'
import Money from '@components/Money'
import { Sorting } from '@enums'
import useIsMobile from '@hooks/useIsMobile'
import useNavigate from '@hooks/useNavigate'
import useWeekly from '@hooks/useWeekly'
import connectionUtils from '@lib/connection'
import dateUtils from '@lib/date'
import sort from '@lib/sorting'
import url from '@lib/url'
import { ConnectionsLoaderParams } from '@loaders/connections'
import { useLocationName } from '@loaders/locations'
import Item from '@pages/SearchResult/WeeklyList/Item'
import Stations from '@pages/SearchResult/WeeklyList/Stations'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { Button, Icon, Modal, Notification } from '@ui'

import '@pages/SearchResult/WeeklyList/index.scss'

const WeeklyList = (): ReactElement => {
  const { formatDate, parse } = dateUtils
  const [{ weeklyTrip }] = useSettings()
  const [{ currency, locale, passengers }] = useParams()
  const { t } = useTranslation()
  const isMobile = useIsMobile()
  const { checkoutRedirect } = useNavigate()
  const { searchDays, returnTrip, requiredDays } = weeklyTrip
  const { calculateTotalPrice, getTimeRange } = connectionUtils

  const [connections, setConnections] = useState<Record<string, (Connection | null)[]>>({})
  const [lastSelected, setLastSelected] = useState<(Connection | null)[]>([])
  const [opened, setOpened] = useState<boolean>(false)
  const { disabled, requiredTrips, selectedTripsCount } = useWeekly(connections)

  const connectionsParams = useConnectionsSearchParams()
  const buildRedirectUrl = useCheckoutUrl()
  const tripType = t(`searchResult.weeklyTrip.${returnTrip ? 'return' : 'oneWay'}`)

  const { data: departureLocation, isLoading: departureLocationLoading } = useLocationName({
    locale,
    location: connectionsParams.departureLocation,
  })
  const { data: arrivalLocation, isLoading: arrivalLocationLoading } = useLocationName({
    locale,
    location: connectionsParams.arrivalLocation,
  })

  const connectionsParamsList: ConnectionsLoaderParams[] = useMemo(
    () =>
      new Array(searchDays).fill(connectionsParams).map((param, index) => {
        const date = formatDate(addDays(parse(param.departureDate), index))

        return { ...param, departureDate: date, returnDate: returnTrip ? date : null }
      }),
    [connectionsParams, formatDate, parse, returnTrip, searchDays],
  )

  const handleChange = (data: Record<string, (Connection | null)[]>): void => {
    setConnections({ ...connections, ...data })
  }

  const handleCopyTime = (data: (Connection | null)[]): void => {
    setLastSelected(data)
  }

  const totalPrice = useMemo(
    () => calculateTotalPrice(Object.values(connections).flat(), currency),
    [calculateTotalPrice, connections, currency],
  )

  const handleSubmit = (): void => {
    const sortedList = sort.sortConnectionList(
      Object.values(connections).flat().filter(Boolean) as Connection[],
      Sorting.DepartureTime,
    )

    const connectionIdList = {
      outbounds: sortedList.filter((item, index) => (returnTrip ? !(index % 2) : item)).map(getTimeRange),
      inbounds: sortedList.filter((_, index) => returnTrip && index % 2).map(getTimeRange),
    }

    const params = {
      outbound: { connection: sortedList[0], fareClassCode: sortedList[0].cheapestFareClassCode, seats: [] },
      inbound: { connection: sortedList[1], fareClassCode: sortedList[1].cheapestFareClassCode, seats: [] },
      passengers,
      passengerTypes: null,
      connections: connectionIdList,
    }

    const checkoutUrl = buildRedirectUrl(params)

    checkoutRedirect(url.appendParams(checkoutUrl, { weekly: true }))
  }

  return (
    <div className="weekly-list__wrapper column">
      {!isMobile && (
        <>
          <h2>{t('searchResult.weeklyTrip.title', { requiredTrips })}</h2>
          <Notification type="info" size="medium">
            {t('searchResult.weeklyTrip.info', { requiredTrips, requiredDays, searchDays, tripType })}
          </Notification>
        </>
      )}
      <div className="weekly-list__section mt-4 mb-lg-8">
        {isMobile && (
          <h2 className="row items items-center">
            {t('searchResult.weeklyTrip.title', { requiredTrips })}
            <Icon name="info" size="medium" className="ml-4" onClick={() => setOpened(true)} />
          </h2>
        )}
        <div className="weekly-list">
          <Stations
            departureName={departureLocation?.name}
            arrivalName={arrivalLocation?.name}
            isLoading={departureLocationLoading || arrivalLocationLoading}
            returns={returnTrip}
            isCopy
          />
          {connectionsParamsList.map(param => (
            <Item
              key={param.departureDate}
              connectionParams={param}
              values={connections[param.departureDate]}
              onChange={handleChange}
              onClick={handleCopyTime}
              returns={returnTrip}
              lastSelected={lastSelected}
            />
          ))}
        </div>
        <div className="weekly-list__submit cell-12 cell-lg-3">
          {isMobile && (
            <div className="mb-2">
              {t('searchResult.weeklyTrip.journeys', { selectedTrips: selectedTripsCount, requiredTrips })}
            </div>
          )}
          <Button disabled={disabled} onClick={handleSubmit}>
            <div className="row space-between gap-1">
              <span>{t('searchResult.weeklyTrip.buyTickets')}</span>
              <span>
                <Money {...totalPrice} />
              </span>
            </div>
          </Button>
        </div>
      </div>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title={<h2>{t('searchResult.weeklyTrip.title', { requiredTrips })}</h2>}
        className="weekly-list__info-modal"
      >
        <span className="row">
          {t('searchResult.weeklyTrip.info', { requiredTrips, requiredDays, searchDays, tripType })}
        </span>
      </Modal>
    </div>
  )
}

export default WeeklyList

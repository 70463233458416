import React, { ReactElement } from 'react'

import Page, { PageProps } from '@components/SidebarMenu/pages/Page'
import useSearchParams from '@hooks/useSearchParams'
import { useTranslation } from '@lib/i18n'
import localeUtils from '@lib/locale'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

import '@components/SidebarMenu/pages/page.scss'

interface LocalePageProps {
  onClose: () => void
}

const LocalePage = ({ onClose }: LocalePageProps): ReactElement => {
  const [{ locale: current }] = useParams()
  const [_, setSearchParams] = useSearchParams()
  const [
    {
      locale: { supported },
    },
  ] = useSettings()

  const setLocale = (locale: Locale): void => {
    setSearchParams(current => ({ ...current, locale }))
  }
  const { t } = useTranslation()

  const pageProps: PageProps<Locale> = {
    title: t('header.localeSelectLabel'),
    list: supported,
    current,
    getIcon: (locale: Locale) => (
      <img className="locale-list__image" src={localeUtils.getFlagImage(locale)} alt={locale} />
    ),
    getName: (locale: Locale) => localeUtils.getName(locale),
    onChange: (locale: Locale) => {
      setLocale(locale)
      onClose()
    },
    onClose,
  }

  return <Page {...pageProps} />
}

export default LocalePage

import React from 'react'

import useIsMobile from '@hooks/useIsMobile'
import InvoiceDesktop from '@pages/Checkout/Form/Invoice/Desktop'
import InvoiceMobile from '@pages/Checkout/Form/Invoice/Mobile'

import '@pages/Checkout/Form/Invoice/index.scss'

const Invoice = () => {
  const isMobile = useIsMobile()

  return isMobile ? <InvoiceMobile /> : <InvoiceDesktop />
}

export default Invoice

import React, { ReactElement } from 'react'

import CountryField from '@components/CountryField'
import { useTranslation } from '@lib/i18n'
import { required } from '@lib/validators'

interface CitizenshipProps {
  name: string
}

const Citizenship = ({ name }: CitizenshipProps): ReactElement => {
  const { t } = useTranslation()

  return (
    <CountryField
      label={t('checkout.passengerDetails.countryCode')}
      name={`${name}.nationality`}
      validate={required}
      required
    />
  )
}

export default Citizenship

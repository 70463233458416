import config from '@config'

const getFixedClass = (retailerPartnerNumber: RetailerNumber, params: Record<string, any>): string | null => {
  const carrierCode = params.marketingCarrierCode
  const rpnSetting = config.forcedFareClass[retailerPartnerNumber]
  const carrierSetting = config.forcedFareClass[retailerPartnerNumber]?.[carrierCode]
  const isOneWay = params.returnDate == null && params.returnDepartureDate == null

  const setting = carrierSetting ?? rpnSetting

  if (!setting) return null
  if (isOneWay) return setting.oneWay
  else return setting.twoWay
}

const getCheckoutFixedClass = (retailerPartnerNumber: RetailerNumber, params: Record<string, any>): string | null => {
  const setting = getFixedClass(retailerPartnerNumber, params)
  const forcedCheckoutClass = config.forcedFareClass[retailerPartnerNumber]?.checkout
  const weeklyForcedCheckoutClass = params.weekly && config.forcedFareClass[retailerPartnerNumber]?.weekly

  if (weeklyForcedCheckoutClass) return weeklyForcedCheckoutClass

  return forcedCheckoutClass ?? setting
}

const getMedia = (media: Media.Data, fareClass: string): string[] =>
  media.fare_classes?.reduce<string[]>(
    (acc, { code, images, videos }) => (code === fareClass ? [...acc, ...images, ...videos] : acc),
    [],
  )

const getYoutubeId = (url: string): string | undefined => {
  const regex = /(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/

  return url.match(regex)?.[1]
}

const validateVideoUrl = (url: string): boolean => {
  const regex = /\bhttps?:\/\/\S+\.(mp4|avi|mov|wmv|flv|mkv|webm)\b/

  return regex.test(url)
}

const fareClassUtils = {
  getFixedClass,
  getCheckoutFixedClass,
  getMedia,
  getYoutubeId,
  validateVideoUrl,
}

export default fareClassUtils

import { Field } from 'formik'
import React, { ReactElement, useCallback, useMemo } from 'react'

import { useTranslation } from '@lib/i18n'
import { required } from '@lib/validators'
import { useSettings } from '@queries/settings'
import { DropdownItem } from '@ui/Dropdown'
import DropdownField from '@ui/Dropdown/Field'
import InputField from '@ui/Input/Field'

interface DocumentTypeProps {
  name: string
}

const DocumentType = ({ name }: DocumentTypeProps): ReactElement => {
  const { t } = useTranslation()
  const [{ governmentId, cpf }] = useSettings()

  const translateType = useCallback(
    (type: GovernmentIdType) => {
      return t(`checkout.passengerDetails.governmentIdTypes.${type}`)
    },
    [t],
  )

  const options = useMemo(
    () =>
      governmentId?.types
        // If the governmentId type is `tax_number` and `cpf.perPassenger` flag is `true`,
        // we should not show the CPF option in the dropdown
        .filter(type => {
          return !(cpf.perPassenger && type === 'tax_number')
        })
        .map<DropdownItem<string>>(value => ({
          value,
          label: translateType(value),
        })),
    [governmentId?.types, cpf.perPassenger, translateType],
  )

  return (
    <>
      <Field
        component={DropdownField}
        label={t('checkout.passengerDetails.governmentIdType')}
        placeholder={t('checkout.passengerDetails.governmentIdType')}
        name={`${name}.governmentIdType`}
        items={options}
        required
      />
      <Field
        component={InputField}
        label={t('checkout.passengerDetails.governmentId')}
        name={`${name}.governmentId`}
        validate={required}
        required
      />
      {cpf.perPassenger && (
        <Field
          component={InputField}
          label={t('checkout.passengerDetails.governmentIdTypes.tax_number')}
          name={`${name}.tax_id`}
          validate={required}
          required
        />
      )}
    </>
  )
}

export default DocumentType

import { PassengersTypesResponse } from '@api/passengerTypes'
import config from '@config'
import { useGAParams } from '@hooks/useGaParams'
import useSearchParams from '@hooks/useSearchParams'
import { SeatsList } from '@hooks/useSeatsController'
import amendmentUtils from '@lib/amendment'
import amplitude from '@lib/analytics/amplitude'
import date from '@lib/date'
import fare from '@lib/fare'
import paramsUtils from '@lib/params'
import passengersUtils, { PASSENGERS_CODE } from '@lib/passengers'
import seatUtils from '@lib/seatSelection'
import url from '@lib/url'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'
import { usePassengerTypes } from '@stores/passengerTypes'

export interface TripData {
  outbound: {
    connection: Connection
    fareClassCode: string
    seats?: SeatsList
  }
  inbound?: {
    connection: Connection
    fareClassCode: string
    seats?: SeatsList
  } | null
  passengers: Passenger.Param[] | null
  passengerTypes: PassengersTypesResponse | null
  connections?: WeeklyConnectionsParams
  email?: string
  phone?: string
}

const DEFAULT_TYPE = PASSENGERS_CODE.adult

const useCheckoutUrl = (): ((data: TripData) => string) => {
  const [params] = useParams()
  const [queryParams] = useSearchParams()
  const { bookingId } = params
  const [{ customDomain, checkout, passengerTypesList, quickReservation, ancillaries, fareClasses }] = useSettings()
  const gaParams = useGAParams()
  const [passengerList] = usePassengerTypes()
  const { getTypeByAge, getPaxCount, getDefaultPassenger } = passengersUtils
  const utmParams = paramsUtils.getUtmParams(window.location.search)

  const getFareClassPrice = (fareClass: string, connection: Connection): number | undefined =>
    fareClass === connection.cheapestFareClassCode
      ? connection.cheapestTotalAdultPrice.fractional
      : fare.getFareByCode(fareClass, connection)?.price.fractional

  const calculateTripPrice = ({ outbound, inbound }: TripData): number => {
    const outboundPrice = getFareClassPrice(outbound.fareClassCode, outbound.connection)
    const inboundPrice = inbound ? getFareClassPrice(inbound.fareClassCode, inbound.connection) : null

    return (outboundPrice ?? /* istanbul ignore next */ 0) + (inboundPrice ?? 0)
  }

  const calculateTotalPrice = (data: TripData): number => {
    const { outbound, inbound } = data
    const outboundSeats = seatUtils.flatten(outbound.seats)
    const inboundSeats = seatUtils.flatten(inbound?.seats)
    const seatsPrice = seatUtils.sumPrice(outboundSeats) + seatUtils.sumPrice(inboundSeats)
    const multiplier = outboundSeats.length || 1

    return seatsPrice + calculateTripPrice(data) * multiplier
  }

  const getPassengers = (
    types: PassengersTypesResponse | null,
    passengers: Passenger.Param[] | null,
    paxCount: number,
  ): Passenger.Param[] | null => {
    if (!passengers) return new Array(paxCount).fill(getDefaultPassenger())

    const list = (types ?? passengerList.types) as Passenger.Type[]

    return passengerTypesList.multiple
      ? passengers.map(pax => ({ ...pax, type: getTypeByAge(list, Number(pax.maxAge))?.code ?? DEFAULT_TYPE }))
      : passengers
  }

  const buildRedirectUrl = (data: TripData): string => {
    const { outbound, inbound, passengers, passengerTypes, email = null, phone = null, connections } = data

    const outboundSeats = outbound.seats?.[0] ?? []
    const pax = passengerTypesList.enabled ? getPaxCount(passengers) : params.pax
    const isDiscountedSeats = Object.values(outbound.seats ?? {}).some(item =>
      item.some(el => !!el.limitations?.length),
    )
    const isPassengerData = passengerTypesList.enabled || params.bookingId || isDiscountedSeats
    const paramPassengers = getPassengers(passengerTypes, passengers, pax)
    const isAvoidVacancy = config.amendments.avoidVacancy.byRpn.includes(params.retailerPartnerNumber)
    const isVacancySettingsDisabled = fareClasses.displayOn === 'nowhere' && !ancillaries.enabled

    const parameters = {
      arrivalStationCode: outbound.connection.arrivalStation.code,
      arrivalTime: date.parse(outbound.connection.arrivalTime, 'UTC'),
      currency: params.currency,
      departureStationCode: outbound.connection.departureStation.code,
      departureTime: date.parse(outbound.connection.departureTime, 'UTC'),
      fareClass: outbound.fareClassCode,
      returnFareClass: quickReservation.enabled ? inbound?.fareClassCode : null,
      locale: params.locale,
      marketingCarrierCode: outbound.connection.marketingCarrier.code,
      pax: outboundSeats.length || pax,
      passengers: isPassengerData ? paramPassengers : null,
      retailerPartnerNumber: params.retailerPartnerNumber,
      retailerBookingNumber: params.retailerBookingNumber,
      returnDepartureTime: inbound ? date.parse(inbound.connection.departureTime, 'UTC') : null,
      returnArrivalTime: inbound ? date.parse(inbound.connection.arrivalTime, 'UTC') : null,
      seats: {
        outbound: outbound.seats ? seatUtils.toUrlParams(outbound.seats) : null,
        inbound: inbound?.seats ? seatUtils.toUrlParams(inbound.seats) : null,
      },
      deviceId: amplitude.getDeviceId(),
      cards: params.cards,
      mode: queryParams.mode,
      price: calculateTotalPrice(data),
      bookingId: params.bookingId,
      ga: gaParams,
      parentDomain: params.parentDomain,
      email,
      phone,
      utmParams,
      connections: connections ?? null,
    }

    if ((isAvoidVacancy || isVacancySettingsDisabled) && !!bookingId) {
      amendmentUtils.setSessionStorage(bookingId, {
        ...amendmentUtils.getInitialStorage(),
        fareClass: outbound.fareClassCode,
      })

      return url.booking.payment(parameters)
    }

    if (checkout.type === 'new') return url.checkout.full(parameters, customDomain.enabled)
    if (checkout.type === 'express') {
      return url.checkout.express({ ...parameters, passengers: paramPassengers }, customDomain.enabled)
    }

    return url.checkout.old.full(parameters)
  }

  return buildRedirectUrl
}

export default useCheckoutUrl

import { useQueries } from '@tanstack/react-query'
import { useFormikContext } from 'formik'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'

import vacancyAPI, { VacancyResponse } from '@api/vacancy'
import { ApiError } from '@lib/api'
import bem from '@lib/bem'
import dateUtils from '@lib/date'
import { useTranslation } from '@lib/i18n'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useVacancyProps } from '@pages/Checkout/hooks/useVacancyProps'
import { useParams } from '@stores/params'

const SEPARATOR = '-'

const getDate = (date: string | undefined, isDateFormat?: boolean): string | null => {
  if (!date || !dateUtils.isValidDateString(date)) return null
  if (isDateFormat) return dateUtils.formatDate(dateUtils.parse(date, 'UTC'))

  return dateUtils.formatTime(dateUtils.parse(date, 'UTC'))
}

const WeeklyVacancyLoader = (): ReactElement | null => {
  const { t } = useTranslation()
  const [errorCodes, setErrorCodes] = useState<string[]>([])
  const [{ connections }] = useParams()
  const {
    values: {
      passengers,
      fareClass,
      meta: { cards },
    },
    setFieldValue,
  } = useFormikContext<CheckoutFormData>()
  const vacancyProps = useVacancyProps(passengers)

  const handleSuccess = useCallback(
    (data: VacancyResponse[]): void => {
      if (!data.every(({ vacant }) => vacant)) {
        setFieldValue('price', {})
        return
      }

      const price = data.reduce((acc, curr) => acc + curr.price.fractional, 0)

      setFieldValue('vacancy', data[0])
      setFieldValue('price', { fractional: price, currency: data[0].price.currency })
      setFieldValue('fees', data[0].fees)
      setFieldValue('priceError', null)
    },
    [setFieldValue],
  )
  const handleError = useCallback(
    (error: ApiError): void => {
      const errorCodes =
        Array.isArray(error.data?.errors) && error.data.errors?.map(({ code }) => code.replace(/\./g, SEPARATOR))

      errorCodes && setErrorCodes(errorCodes)
      setFieldValue('priceError', error)
      setFieldValue('vacancy', null)
      setFieldValue('price', {})
    },
    [setFieldValue],
  )

  const { isLoading, data, error, status } = useQueries({
    queries: (connections?.outbounds ?? /* istanbul ignore next */ []).map((outboundDate, index) => {
      const regex = /-(?=\d{4}-\d{2}-\d{2}T)/
      const inboundDate = connections?.inbounds?.[index]

      const [departure, arrival] = outboundDate.split(regex)
      const [returnDeparture, returnArrival] = inboundDate?.split(regex) ?? []

      const dates = {
        departureDate: getDate(departure, true),
        departureTime: getDate(departure),
        arrivalDate: getDate(arrival, true),
        arrivalTime: getDate(arrival),
        returnDepartureDate: getDate(returnDeparture, true),
        returnDepartureTime: getDate(returnDeparture),
        returnArrivalDate: getDate(returnArrival, true),
        returnArrivalTime: getDate(returnArrival),
      }

      return {
        queryKey: ['weeklyVacancyLoader', outboundDate, vacancyProps, cards, fareClass],
        queryFn: () => vacancyAPI.load({ ...vacancyProps, ...dates, cards, fareClass }),
        enabled: Object.values(dates).every(Boolean) && !!fareClass,
        staleTime: Infinity,
      }
    }),
    combine: results => {
      return {
        data: results.map(({ data }) => data).filter(Boolean) as VacancyResponse[],
        isLoading: results.some(({ isLoading }) => isLoading) && results.some(({ status }) => status === 'pending'),
        error: results.find(Boolean)?.error as ApiError,
        status: results.map(({ status }) => status),
      }
    },
  })

  useEffect(() => {
    setFieldValue('isVacancyLoading', isLoading)
  }, [setFieldValue, isLoading])

  useEffect(() => {
    error && handleError(error)
  }, [error, handleError])

  useEffect(() => {
    if (status.some(state => state !== 'success')) return

    data.length && handleSuccess(data)
  }, [data, error, status, handleSuccess])

  if (isLoading || (!error && data.every(({ vacant }) => vacant))) {
    return null
  }

  const errorMessages = [
    ...new Set(
      (errorCodes.length > 0 ? errorCodes : ['notVacant']).map(code =>
        t(`errors.codes.${code}.title`, { defaultValue: t('checkout.notEnoughSeats') }),
      ),
    ),
  ]

  return (
    <>
      {errorMessages.map(message => (
        <div key={message} className={bem('checkout-form', 'message')}>
          {message}
        </div>
      ))}
    </>
  )
}

export default WeeklyVacancyLoader

import React, { FocusEvent } from 'react'

import bem from '@lib/bem'

import '@ui/Switch/index.scss'

interface SwitchProps {
  checked?: boolean
  onChange?: () => void
  disabled?: boolean
  onBlur?: (e: FocusEvent) => void
}

const Switch = ({ checked, onChange, disabled, onBlur }: SwitchProps) => {
  return (
    <div className={bem('ui-switch', { disabled, checked })}>
      <input
        className="ui-switch__input"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        onBlur={onBlur}
      />
      <div className="ui-switch__thumb" />
      <div className="ui-switch__track" />
    </div>
  )
}

export default Switch

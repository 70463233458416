import React, { ReactElement } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import useIsTablet from '@hooks/useIsTablet'
import Cell from '@pages/SearchResult/WeeklyList/Item/Cell'
import Column from '@pages/SearchResult/WeeklyList/Item/Column'
import TimeSkeleton from '@pages/SearchResult/WeeklyList/Skeleton'
import { Icon, Skeleton } from '@ui'

import '@pages/SearchResult/WeeklyList/Stations/index.scss'

interface StationsProps {
  departureName?: string
  arrivalName?: string
  returns: boolean
  isLoading?: boolean
  isCopy?: boolean
}

const Stations = ({ departureName, arrivalName, returns, isLoading, isCopy = false }: StationsProps): ReactElement => {
  const isMobile = useIsMobile()
  const isTablet = useIsTablet()

  const showFiller = !isMobile && !isTablet

  return (
    <Column header returns={returns} fixed={!isMobile}>
      {showFiller && (
        <Cell header={!isMobile}>
          <div className="weekly-list__filler" />
        </Cell>
      )}
      <Cell className="weekly-list__location-outbound cell-12 row">
        <Skeleton.List loading={!!isLoading} amount={1} Skeleton={TimeSkeleton}>
          <span className="column row-lg wrap gap-1">
            <span className="weekly-list__location-name cell-lg-10">{departureName}</span>
            <Icon name="arrow-right" size="large" />
            <span className="weekly-list__location-name">{arrivalName}</span>
          </span>
        </Skeleton.List>
      </Cell>
      {returns && (
        <Cell className="weekly-list__location-inbound cell-12 row">
          <Skeleton.List loading={!!isLoading} amount={1} Skeleton={TimeSkeleton}>
            <span className="column row-lg wrap gap-1">
              <span className="weekly-list__location-name cell-lg-10">{arrivalName}</span>
              <Icon name="arrow-right" size="large" />
              <span className="weekly-list__location-name">{departureName}</span>
            </span>
          </Skeleton.List>
        </Cell>
      )}
      {showFiller && isCopy && (
        <Cell header={!isMobile} removeBorders>
          <div className="weekly-list__filler" />
        </Cell>
      )}
    </Column>
  )
}

export default Stations

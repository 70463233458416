import { t } from 'i18next'
import { isPossiblePhoneNumber } from 'libphonenumber-js/max'

export const phoneNumber: Validator = (value?: string) =>
  value && !isPossiblePhoneNumber(value) ? t('errors.phoneInvalid') : undefined

export const USPhone: Validator = (value?: string) => {
  const usPhoneNumberRegex = /^(\+1\s?)?(\(?(?!(684|671|670|787|939|340))([2-9][0-9]{2})\)?)[-.\s]?\d{3}[-.\s]?\d{4}$/

  return value && !usPhoneNumberRegex.test(value) ? t('errors.phoneInvalid') : undefined
}

import { Field, useFormikContext } from 'formik'
import React, { useCallback, useEffect, useMemo } from 'react'

import { PaymentMethodStatus } from '@enums'
import { ConfirmPaymentParams, FinishPaymentParams, SubmitFormEvent } from '@hooks/useBookingFlow'
import { useTranslation } from '@lib/i18n'
import { required } from '@lib/validators'
import { useBookingStatusPolling } from '@loaders/bookingStatusPolling'
import { PaymentMethod } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import PaymentLabel from '@pages/Checkout/Payment/Label'
import { usePaymentTerminals } from '@queries/usePaymentTerminals'
import { useParams } from '@stores/params'
import DropdownField from '@ui/Dropdown/Field'

const TerminalForm = () => {
  const { t } = useTranslation()
  const terminals = usePaymentTerminals()
  const { setFieldValue } = useFormikContext<CheckoutFormData>()

  const defaultTerminal = useMemo(() => localStorage.getItem('defaultTerminal'), [])

  const terminalOptions = useMemo(() => {
    if (!terminals.data) return defaultTerminal ? [{ value: defaultTerminal }] : /* istanbul ignore next */ []

    return terminals.data.filter(({ status }) => status === 'active').map(({ id }) => ({ value: id }))
  }, [defaultTerminal, terminals.data])

  useEffect(() => {
    setFieldValue('platform.appId', defaultTerminal)
  }, [defaultTerminal, setFieldValue])

  return (
    <div className="cell-6" data-tag="terminal-device">
      <Field
        onChange={(value: string) => localStorage.setItem('defaultTerminal', value)}
        component={DropdownField}
        label={t('checkout.terminal.activeDevice')}
        name="platform.appId"
        items={terminalOptions}
        validate={required}
        required
      />
    </div>
  )
}

export const useTerminal = (finishPayment: (params: FinishPaymentParams) => void): AdyenTypes.Method => {
  const getOption = useCallback(() => {
    return {
      value: 'terminal',
      label: <PaymentLabel type="terminal" showName />,
      content: <TerminalForm />,
    }
  }, [])

  const submitForm = useCallback<SubmitFormEvent>(
    ({ platform }) => ({
      platform: { platformType: 'terminal', osType: 'android', appId: platform?.appId },
      paymentMethodData: { type: 'terminal' },
    }),
    [],
  )

  const statusPolling = useBookingStatusPolling({
    onSuccess: params => finishPayment({ ...params, action: 'redirect' }),
  })
  const confirmPayment = useCallback(
    async (params: ConfirmPaymentParams) => {
      await statusPolling.mutateAsync(params.bookingFormId)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [statusPolling.mutate],
  )

  const [params] = useParams()
  const status = useMemo(
    () => (params.mode === 'embed' ? PaymentMethodStatus.Ready : PaymentMethodStatus.Rejected),
    [params.mode],
  )

  return useMemo<PaymentMethod>(
    () => ({ status, getOption, on: { submitForm, confirmPayment } }),
    [confirmPayment, getOption, status, submitForm],
  )
}

import { differenceInMinutes, parseJSON, addMinutes, subSeconds } from 'date-fns'

import {
  Reservation,
  CreateResponse as ReservationCreateResponse,
  ConfirmRequest as ReservationConfirmRequest,
  PayRequest as ReservationPayRequest,
} from '@api/reservation'
import { NormalRequest as CreateNormalRequest, QuickRequest as CreateQuickRequest } from '@api/reservation/create'
import config from '@config'
import ancillaryUtils, { AncillariesFormData } from '@lib/ancillary'
import checkoutUtils from '@lib/checkout'
import connectionUtils from '@lib/connection'
import dateUtils from '@lib/date'
import paramsUtils from '@lib/params'
import utils from '@lib/utils'
import weeklyUtils from '@lib/weekly'
import {
  CheckoutFormData,
  CompanyInvoice,
  IndividualInvoice,
  InvoiceData,
} from '@pages/Checkout/hooks/useInitialFormValues'
import { ParamsStore } from '@stores/params'

const RESERVATION_DEFAULT_MINUTES = 10
const RESERVATION_SHIFT_SECONDS = 30

interface ReservationData extends Reservation {
  ancillaries?: AncillariesFormData
}

const adjustTimeZones = (reservationData: ReservationCreateResponse): Reservation => {
  const createdAt = dateUtils.today()

  const reservationPeriod = periodInMinutes(reservationData)

  const expiresAtSetMinutes = addMinutes(createdAt, reservationPeriod)
  const expiresAt = subSeconds(expiresAtSetMinutes, RESERVATION_SHIFT_SECONDS)

  return {
    price: reservationData.price,
    expiresAt,
    createdAt,
    period: reservationPeriod,
    bookingFormId: reservationData.bookingFormId,
    id: reservationData.id,
    fees: reservationData.fees,
  }
}

const periodInMinutes = (reservationData: ReservationCreateResponse): number => {
  return reservationData.expiresAt != null
    ? differenceInMinutes(parseJSON(reservationData.expiresAt), parseJSON(reservationData?.createdAt))
    : RESERVATION_DEFAULT_MINUTES
}

const setSessionStorage = (reservationData: ReservationData, params: ParamsStore): void => {
  sessionStorage.setItem(sessionKey(params), JSON.stringify(reservationData))
}

const setCheckoutPath = (reservationId: string, checkoutUrl: string): void => {
  sessionStorage.setItem(reservationId, checkoutUrl)
}

const getCheckoutPath = (reservationId: string): string | null => {
  return sessionStorage.getItem(reservationId)
}

// I've recently changed the way keys are stored, so we need to ensure backwards compatibility for now
const elevateKeys = (params: Record<string, any>): Record<string, any> => {
  return Object.entries(params).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key.toUpperCase()]: value,
    }),
    {},
  )
}

const getSessionStorage = (params: ParamsStore): ReservationData => {
  const key = sessionKey(params)

  const parsedData = JSON.parse(sessionStorage.getItem(key) as string)

  if (parsedData != null) {
    parsedData.expiresAt = dateUtils.parse(parsedData.expiresAt) // convert to user's timezone
    parsedData.createdAt = dateUtils.parse(parsedData.createdAt) // convert to user's timezone
    parsedData.ancillaries = parsedData.ancillaries && elevateKeys(parsedData.ancillaries)
  }

  return parsedData
}

const resetSessionStorage = (params: ParamsStore): void => {
  const key = sessionKey(params)
  const storage = getSessionStorage(params)

  sessionStorage.removeItem(key)
  /* istanbul ignore next */
  storage && sessionStorage.removeItem(String(storage.id))
}

const sessionKey = (params: ParamsStore): string => {
  const seats = params.seats != null ? JSON.stringify(params.seats) : null

  const tripInfo = [
    'ReservationData',
    params.retailerPartnerNumber,
    params.marketingCarrierCode,
    params.arrivalLocation?.code,
    params.departureLocation?.code,
    params.fareClass,
    params.arrivalDate,
    params.arrivalTime,
    params.departureDate,
    params.departureTime,
    params.returnDepartureDate,
    params.returnDepartureTime,
    params.returnArrivalDate,
    params.returnArrivalTime,
    params.pax,
    seats,
  ]

  return tripInfo.filter(param => param != null).join('-')
}

const getInvoiceParams = (invoice: InvoiceData): Partial<ReservationConfirmRequest> => {
  const { country, address, zipCode, state, city } = invoice
  const { firstName, lastName, fiscalCode } = invoice as IndividualInvoice
  const { businessName, vatNumber, taxCode } = invoice as CompanyInvoice

  return utils.object.compact({
    firstName: firstName ?? null,
    lastName: lastName ?? null,
    taxId: fiscalCode || taxCode || null,
    businessName: businessName ?? null,
    vatNumber: vatNumber ?? null,
    countryCode: country,
    streetAndNumber: address,
    zipCode,
    state,
    city,
  })
}

interface WeeklyCustomFields {
  name: CustomFieldName
  value: string
}

const getWeeklyConnections = (params: ParamsStore): WeeklyCustomFields[] | null => {
  if (!params.connections?.outbounds || !params.connections?.inbounds) return null

  const { getSeparator } = weeklyUtils

  return [
    { name: 'weekly_outbounds', value: params.connections.outbounds.join(getSeparator('date')) },
    { name: 'weekly_inbounds', value: params.connections.inbounds.join(getSeparator('date')) },
  ]
}

const buildConfirmParams = (
  data: CheckoutFormData,
  params: ParamsStore,
  connection: Connection | null,
): ReservationConfirmRequest => {
  const passengers = data.passengers.map(pax => utils.object.removeKey(pax, 'cards'))
  const utmParams = paramsUtils.getUtmParams(window.location.search)

  return utils.object.compact<ReservationConfirmRequest>({
    retailerBookingNumber: params.retailerBookingNumber,
    paymentMethod: data.paymentMethod,
    paymentProvider: data.paymentProvider,
    passengers: checkoutUtils.assignSeatsToPassengers({ passengers, seatAncillaries: true }),
    description: config.adyen.description,
    email: data.contact.email || null,
    phone: data.contact.phone,
    termsAccepted: data.termsAndPrivacy,
    sendMarketingEmails: data.marketing,
    pax: data.passengers.length,
    fareClass: data.fareClass,
    cardHolderName: data.holderName,
    express: false,
    numberOfInstallments: data.numberOfInstallments,
    cpf: data.cpf,
    cardData: data.cardData,
    paymentMethodData: data.paymentMethod === 'pix' ? { type: 'pix' } : data.paymentMethodData,
    browserInfo: data.browserInfo,
    bookingFormId: data.reservationData?.bookingFormId as number,
    id: data.reservationData?.id as number,
    streetAndNumber: data.streetAndNumber,
    city: data.city,
    countryCode: data.countryCode,
    state: data.state,
    zipCode: data.zipCode,
    firstName: data.passengers[0].firstName,
    lastName: data.passengers[0].lastName,
    markupFee: checkoutUtils.calculateTotalFees(data.reservationData?.fees),
    deviceFingerprint: data.deviceFingerprint,
    paymentAttemptReference: data.paymentAttemptReference,
    offerId: connection?.offerId,
    platform: data.platform,
    shopperReference: data.shopperReference ?? null,
    paymentToken: data.paymentToken ?? null,
    utmParams: Object.keys(utmParams).length ? utmParams : null,
    customFields: getWeeklyConnections(params),
    ...(data.invoice && getInvoiceParams(data.invoice)),
  })
}

const buildPayParams = (data: CheckoutFormData): ReservationPayRequest => ({
  id: data.reservationCode ?? /* istanbul ignore next */ '',
  bookingFormId: data.reservationInfo?.bookingFormId as number,
  paymentMethod: data.paymentMethod,
  paymentProvider: data.paymentProvider,
  passengers: data.reservationInfo?.passengers ?? /* istanbul ignore next */ [],
  description: config.adyen.description,
  cardHolderName: data.holderName,
  cpf: data.cpf,
  cardData: data.cardData,
  paymentMethodData: data.paymentMethod === 'pix' ? { type: 'pix' } : data.paymentMethodData,
  browserInfo: data.browserInfo,
  streetAndNumber: data.streetAndNumber,
  city: data.city,
  countryCode: data.countryCode,
  state: data.state,
  zipCode: data.zipCode,
  deviceFingerprint: data.deviceFingerprint,
  termsAccepted: data.termsAndPrivacy,
})

export interface CreateRequestProps {
  params: ParamsStore
  data: CheckoutFormData
  outbound: Connection
  inbound?: Connection | null
}

type CommonCreateParams = Omit<CreateNormalRequest, 'passengers'>

const buildOfferId = (outbound: Connection, params: ParamsStore): string | null => {
  if (!params.connections?.outbounds || !params.connections?.inbounds) return outbound.offerId

  const { getDepartureDate } = connectionUtils
  const { getSeparator } = weeklyUtils
  const outbounds = params.connections.outbounds
  const inbounds = params.connections.inbounds

  const string = outbounds
    .map((id, index) => `${getDepartureDate(id)}${getSeparator('trip')}${getDepartureDate(inbounds[index])}`)
    .join(getSeparator('date'))

  return btoa(string)
}

const commonCreateParams = ({ params, inbound, outbound, data }: CreateRequestProps): CommonCreateParams => ({
  retailerBookingNumber: params.retailerBookingNumber,
  arrivalStationCode: outbound.arrivalStation.code,
  departureStationCode: outbound.departureStation.code,
  currency: params.currency,
  locale: params.locale,
  marketingCarrierCode: params.marketingCarrierCode as string,
  departureTime: outbound.departureTime,
  arrivalTime: outbound.arrivalTime,
  retailerPartnerNumber: params.retailerPartnerNumber,
  fareClass: params.fareClass,
  returnDepartureTime: inbound?.departureTime,
  returnArrivalTime: inbound?.arrivalTime,
  termsVersion: data.vacancy?.termsAndConditions?.version,
  city: data.city,
  state: data.state,
  zipCode: data.zipCode,
  countryCode: data.countryCode,
  streetAndNumber: data.streetAndNumber,
  offerId: buildOfferId(outbound, params),
})

const buildCreateParams = (props: CreateRequestProps): CreateNormalRequest => ({
  ...commonCreateParams(props),
  passengers: checkoutUtils.passengersSeatsAndTypes({ data: props.data, seatAncillaries: true }),
})

const buildQuickCreateParams = (props: CreateRequestProps): CreateQuickRequest => {
  const passengers = checkoutUtils.assignSeatsToPassengers({
    passengers: props.data.passengers,
    data: props.data,
    seatAncillaries: true,
  })

  return utils.object.compact<CreateQuickRequest>({
    ...commonCreateParams(props),
    countryCode: props.data.countryCode,
    reservationHold: props.data.paymentMethod === 'hold_reservation',
    title: 'mr',
    firstName: props.data.passengers[0].firstName as string,
    lastName: props.data.passengers[0].lastName as string,
    email: props.data.contact.email || null,
    phone: props.data.contact.phone,
    termsAccepted: props.data.termsAndPrivacy,
    passengers,
    governmentId: passengers[0].governmentId,
    governmentIdType: passengers[0].governmentIdType,
    governmentIdIssuingCountry: passengers[0].governmentIdIssuingCountry,
    governmentIdIssuingState: passengers[0].governmentIdIssuingState,
    governmentIdValidityExpiration: passengers[0].governmentIdValidityExpiration,
    gender: props.data.passengers[0].gender as string,
    cards: props.data.meta.cards,
    ancillaries: ancillaryUtils.getBookingAncillariesParams(props.data.ancillaries),
    fareClass: props.data.fareClass,
    returnFareClass: props.data.returnFareClass,
    markupFee: checkoutUtils.calculateTotalFees(props.data.fees),
    ...(props.data.invoice && getInvoiceParams(props.data.invoice)),
  })
}

export default {
  buildConfirmParams,
  buildCreateParams,
  buildQuickCreateParams,
  buildPayParams,
  setSessionStorage,
  getSessionStorage,
  resetSessionStorage,
  adjustTimeZones,
  setCheckoutPath,
  getCheckoutPath,
}

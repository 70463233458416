import React, { ReactElement } from 'react'

import { useTranslation } from '@lib/i18n'

const BookedOut = (): ReactElement => {
  const { t } = useTranslation()

  return (
    <div className="weekly-list__booked">
      <div>{t('searchResult.weeklyTrip.noTrips')}</div>
    </div>
  )
}

export default BookedOut

import React, { ReactElement } from 'react'

import Station from '@components/Station'
import connectionUtils from '@lib/connection'
import date from '@lib/date'
import Cell from '@pages/SearchResult/WeeklyList/Item/Cell'
import Column from '@pages/SearchResult/WeeklyList/Item/Column'
import Stations from '@pages/SearchResult/WeeklyList/Stations'

import '@pages/Checkout/BookingDetails/WeeklyConnections/index.scss'

interface WeeklyConnectionsProps {
  connections: WeeklyConnectionsParams
  departureLocation?: string
  arrivalLocation?: string
}

const WeeklyConnections = ({
  connections,
  departureLocation,
  arrivalLocation,
}: WeeklyConnectionsProps): ReactElement => (
  <div className="weekly-connections">
    <Stations
      departureName={departureLocation}
      arrivalName={arrivalLocation}
      returns={!!connections.inbounds?.length}
    />
    {connections.outbounds.map((id, index) => {
      const outboundDate = connectionUtils.getDepartureDate(id)
      const inboundDate = connectionUtils.getDepartureDate(connections.inbounds?.[index])

      return (
        <Column key={id} returns={!!inboundDate}>
          {outboundDate && (
            <>
              <Cell className="weekly-connections__date" header>
                <Station.Date date={date.parse(outboundDate, 'UTC')} />
              </Cell>
              <Cell className="weekly-connections__departure">{date.formatTime(date.parse(outboundDate, 'UTC'))}</Cell>
            </>
          )}
          {inboundDate && (
            <>
              <Cell className="weekly-connections__arival">{date.formatTime(date.parse(inboundDate, 'UTC'))}</Cell>
            </>
          )}
        </Column>
      )
    })}
  </div>
)

export default WeeklyConnections

import config from '@config'
import connectionUtils from '@lib/connection'
import dateUtils from '@lib/date'

const separators = {
  date: '|',
  trip: '~',
}

const getSeparator = (key: keyof typeof separators): string => separators[key]

const formatDate = (date: string): string => dateUtils.format(dateUtils.parse(date, 'UTC'), 'EEE, dd MMM')

const getConnectionsCount = (connections?: WeeklyConnectionsParams | null): number => {
  if (!connections) return 0

  return Object.values(connections).flat().length
}

const getDatesRange = (connections?: WeeklyConnectionsParams | null): string | null => {
  if (!connections) return null

  const weeklyConnections = Object.values(connections).flat()
  const first = connectionUtils.getDepartureDate(weeklyConnections.at(0))
  const last = connectionUtils.getDepartureDate(weeklyConnections.at(-1))

  return first && last ? `${formatDate(first)} - ${formatDate(last)}` : null
}

const getWeeklyConnections = (fields: Booking.CustomFields[], type: CustomFieldName): string[] =>
  fields.reduce<string[]>((acc, curr) => {
    if (curr.attributes.name !== type) return acc

    return (curr.attributes.value as string).split(getSeparator('date'))
  }, [])

const getCarrierCode = (
  rpn: number,
  paramsCarrierCode: string | null | undefined,
  enabled?: boolean | null,
): string | null | undefined => {
  const searchByCarrier = config.connectionsSearch[rpn]

  if (!searchByCarrier) return paramsCarrierCode
  if (enabled) return searchByCarrier.weekly

  return searchByCarrier.default
}

const weeklyUtils = {
  getConnectionsCount,
  getDatesRange,
  getSeparator,
  getWeeklyConnections,
  getCarrierCode,
}

export default weeklyUtils

import cn from 'classnames'
import React, { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

import useSearchForm from '@components/SearchBar/useSearchForm'
import { SearchParams } from '@components/SearchBar/useSearchFormInitial'
import SearchForm, { SearchFormData } from '@components/SearchForm'
import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import paramsUtils from '@lib/params'
import partners from '@lib/partners'
import passengerUtils from '@lib/passengers'
import url from '@lib/url'
import weeklyUtils from '@lib/weekly'
import { useSettings } from '@queries/settings'
import { useParams } from '@stores/params'

import '@pages/Search/Bar/index.scss'

interface SearchBarProps {
  layout: Direction
  title?: ReactNode
  subTitle?: ReactNode
  textColor?: string
  params?: Partial<SearchParams>
}

const SearchBar = (props: SearchBarProps) => {
  const { layout, title, subTitle } = props
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const { settings, initialValues } = useSearchForm(props.params)
  const [params] = useParams()
  const { currency } = params
  const [{ accommodation: accommodationSetting, passengerTypesList }] = useSettings()
  const searchWrapperClasses = layout === 'row' ? ['column', 'gap-10'] : ['row', 'gap-11']

  const navigateToResultPage = ({
    departureLocation,
    arrivalLocation,
    accommodation,
    ...rest
  }: SearchFormData): void => {
    const uri = url.buildInternal('/result', params, {
      ...rest,
      ...(departureLocation && paramsUtils.flatLocation(departureLocation, 'departure')),
      ...(arrivalLocation && paramsUtils.flatLocation(arrivalLocation, 'arrival')),
      currency,
      pax: passengerTypesList.enabled ? passengerUtils.getPaxCount(rest.passengers) : rest.pax,
      marketingCarrierCode: weeklyUtils.getCarrierCode(
        params.retailerPartnerNumber,
        params.marketingCarrierCode,
        rest.weekly,
      ),
    })

    const partnerLink = partners.buildURL(accommodationSetting.partner, params.locale, {
      departureLocation,
      arrivalLocation,
      ...rest,
    })

    if (accommodation) {
      open(uri, '_blank')
      open(partnerLink, '_self')
    } else {
      navigate(uri)
    }
  }

  const submitSearchForm = (values: SearchFormData): void => {
    navigateToResultPage(values)
  }

  return (
    <div className={cn(...searchWrapperClasses, 'column-sm', 'gap-sm-4', 'search-section')}>
      <div className="cell no-grow">
        <div className={bem('search-section', 'form', { [layout]: true })}>
          <SearchForm
            direction={isMobile ? 'column' : layout}
            initialValues={{ ...initialValues, accommodation: accommodationSetting.enabled }}
            onSubmit={submitSearchForm}
            settings={{ ...settings, accommodation: accommodationSetting.enabled }}
          />
        </div>
      </div>
      {(title ?? subTitle) && (
        <div className={cn('cell', '-order-1-sm', { '-order-1': layout === 'row' })}>
          <div className={`${bem('search-section', 'title-wrapper')} column gap-2`} style={{ color: props.textColor }}>
            <h1 className={bem('search-section', 'title')}>{title}</h1>
            <div className={bem('search-section', 'subtitle')}>{subTitle}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchBar

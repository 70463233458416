import { createStore, useStore, UseStoreHook } from '@lib/store'

export interface BookingStore {
  isAmendment: boolean
}

const store = createStore<BookingStore>({ isAmendment: false })

export const useBooking = (): UseStoreHook<BookingStore> => useStore(store)

export default store

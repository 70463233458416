import cn from 'classnames'
import React, { ReactElement } from 'react'

import bem from '@lib/bem'

import '@pages/SearchResult/WeeklyList/Item/Column.scss'

interface ColumnProps {
  className?: string
  children: React.ReactNode
  header?: boolean
  returns?: boolean
  fixed?: boolean
}

const Column = ({ className, children, header, returns, fixed }: ColumnProps): ReactElement => (
  <div className={cn(className, bem('weekly-list', 'column', { header, returns, fixed }))}>{children}</div>
)

export default Column

import config from '@config'
import { GAParams } from '@hooks/useGaParams'
import linkerParam from '@lib/analytics/ga/linkerParam'
import currency from '@lib/currency'
import date from '@lib/date'
import paramsUtils, { PersistentParams } from '@lib/params'
import url from '@lib/url'
import oldCheckout from '@lib/url/oldCheckout'
import utils from '@lib/utils'

export interface ContextParams extends PersistentParams {
  currency: Currency
  deviceId?: string
  ga?: GAParams
  hideLocations?: boolean | null
  utmParams?: Record<string, string>
}

export interface ConnectionParams {
  departureStationCode: StationCode
  departureTime: Date
  arrivalTime: Date
  arrivalStationCode: StationCode
  returnArrivalTime?: Date | null
  returnDepartureTime?: Date | null
  passengers: Passenger.Param[] | null
  pax?: number
  fareClass: string | string[]
  connections?: WeeklyConnectionsParams | null
}

export interface ExpressParams extends ContextParams, Omit<ConnectionParams, 'arrivalTime'> {
  email?: string | null
}

export interface FullParams extends ContextParams, ConnectionParams {
  price?: number
  seats?: Seat.TripParams
  cards?: DiscountCode.Card[] | null
  returnFareClass?: string | null
  bookingId?: string | null
  email?: string | null
  phone?: string | null
}

type DatesData = Pick<ConnectionParams, 'departureTime' | 'returnArrivalTime' | 'returnDepartureTime'> & {
  arrivalTime?: Date
}
const buildDateParams = (params: DatesData) => ({
  departureDate: date.formatDate(params.departureTime),
  departureTime: date.formatTime(params.departureTime),
  arrivalDate: params.arrivalTime ? date.formatDate(params.arrivalTime) : null,
  arrivalTime: params.arrivalTime ? date.formatTime(params.arrivalTime) : null,
  returnDepartureDate: params.returnDepartureTime ? date.formatDate(params.returnDepartureTime) : null,
  returnDepartureTime: params.returnDepartureTime ? date.formatTime(params.returnDepartureTime) : null,
  returnArrivalDate: params.returnArrivalTime ? date.formatDate(params.returnArrivalTime) : null,
  returnArrivalTime: params.returnArrivalTime ? date.formatTime(params.returnArrivalTime) : null,
})

const redirectUrl = (customDomain: boolean, parentDomain?: string | null): string[] => {
  // temporarily solution for iframe on portal, before we start to use JWT instead of cookies
  if (parentDomain) return [`${window.origin}/checkout`]
  if (!customDomain) return [config.checkoutUrl.new]

  const superDomain = location.hostname.split('.').slice(1).join('.')

  const origin = /* istanbul ignore next */ config.resolve.checkoutPageUrl[superDomain] ?? window.origin

  return [`${origin}/checkout`]
}

const express = (params: ExpressParams, customDomain: boolean = false): string => {
  const parameters = utils.object.compact({
    ...paramsUtils.getPersistentUrlParams(params),
    ...buildDateParams(params),
    ...params.utmParams,
    departureStation: params.departureStationCode,
    arrivalStation: params.arrivalStationCode,
    fareClass: params.fareClass,
    pax: params.passengers?.length,
    passengers: params.passengers,
    express: true,
    currency: params.currency,
    deviceId: params.deviceId,
    parentDomain: params.parentDomain,
    email: params.email ?? null,
    hideLocations: params.hideLocations,
  })

  const baseUrl = redirectUrl(customDomain, params.parentDomain)

  return url.build(baseUrl, parameters)
}

const full = (params: FullParams, customDomain: boolean): string => {
  const price =
    params.price != null
      ? currency.getPriceInEuro({ currency: params.currency, fractional: params.price })
      : /* istanbul ignore next */ null
  const parameters = utils.object.compact({
    ...paramsUtils.getPersistentUrlParams(params),
    ...buildDateParams(params),
    ...params.ga,
    ...params.utmParams,
    _gl: linkerParam.get(),
    departureStation: params.departureStationCode,
    arrivalStation: params.arrivalStationCode,
    fareClass: params.fareClass,
    passengers: params.passengers,
    pax: params.pax,
    currency: params.currency,
    seats: params.seats && utils.object.compact(params.seats),
    deviceId: params.deviceId,
    cards: params.cards,
    mode: params.mode,
    price,
    bookingId: params.bookingId,
    returnFareClass: params.returnFareClass,
    parentDomain: params.parentDomain,
    email: params.email,
    phone: params.phone,
    hideLocations: params.hideLocations,
    connections: params.connections,
  })

  const baseUrl = redirectUrl(customDomain, params.parentDomain)

  return url.build(baseUrl, parameters)
}

export default {
  express,
  full,
  old: oldCheckout,
}

import React, { ReactElement, useState } from 'react'

import ErrorPage from '@components/ErrorPage'
import useIsMobile from '@hooks/useIsMobile'
import { ApiError } from '@lib/api'
import { Icon, Modal, Tooltip } from '@ui'

interface ErrorProps {
  error: ApiError
}

const ConnectionError = ({ error }: ErrorProps): ReactElement => {
  const [opened, setOpened] = useState<boolean>(false)
  const isMobile = useIsMobile()

  return (
    <>
      <Tooltip content={<ErrorPage error={error} />} action="hover" position="top">
        <Icon name="cross-circle" size="large" className="weekly-list__error" onClick={() => setOpened(isMobile)} />
      </Tooltip>
      <Modal opened={opened} onClose={() => setOpened(false)}>
        <ErrorPage error={error} />
      </Modal>
    </>
  )
}

export default ConnectionError

import { Field, useFormikContext } from 'formik'
import React, { ReactElement, useEffect, useMemo, useState } from 'react'

import { DiscountCardsResponse } from '@api/discountCards'
import appliedIcon from '@images/common/applied-tick.svg'
import checkout from '@lib/checkout'
import discount from '@lib/discount'
import { t } from '@lib/i18n'
import { uniq } from '@lib/validators'
import DiscountsSkeleton from '@pages/Checkout/Discounts/Skeleton'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { useParams } from '@stores/params'
import { Skeleton } from '@ui'
import Button from '@ui/Button'
import Icon from '@ui/Icon'
import InputField from '@ui/Input/Field'

export type DiscountCardsState = Record<DiscountCode.Type, string>

const CodeAppliedIcon = (): ReactElement => <img src={appliedIcon} alt="applied" className="font-icon" />

const PROMOCODE_REGEX = /^[0-9a-zA-Z]{4}$/

interface DiscountsProps {
  discountCards?: DiscountCardsResponse
  isLoading: boolean
}

const Discounts = ({ discountCards, isLoading }: DiscountsProps): ReactElement => {
  const {
    values: {
      cards: inputCards,
      isVacancyLoading,
      vacancy,
      meta: { cards },
    },
    setFieldValue,
    getFieldMeta,
  } = useFormikContext<CheckoutFormData>()
  const [error, setError] = useState<Record<string, string>>({})
  const [valueChanged, setValueChanged] = useState<boolean>(false)
  const [_, setParams] = useParams()

  const appliedCards = useMemo<AppliedCard[]>(
    () => vacancy?.appliedCards ?? /* istanbul ignore next */ [],
    [vacancy?.appliedCards],
  )

  const isCardTypeApplied = (type: DiscountCode.Type): boolean => appliedCards.some(({ name }) => name === type)

  const isCardInvalid = useMemo(() => {
    const cardNames = cards?.map(({ name }) => name)

    return cards.length > 0 && appliedCards.filter(({ name }) => cardNames.includes(name)).length === 0
  }, [cards, appliedCards])

  const hasValue = (type: DiscountCode.Type): boolean =>
    Object.entries(inputCards ?? {}).some(([key, value]) => key !== type && Boolean(value))

  const discountFields = useMemo(() => discountCards?.map(({ name }) => name), [discountCards])

  const hasAnyValue = discountFields?.some(fieldName => getFieldMeta(fieldName)?.value !== '')

  const isAnyCardApplied = useMemo(() => {
    const cardNames = discountCards?.map(({ name }) => name)

    return appliedCards.some(({ name }) => cardNames?.includes(name))
  }, [appliedCards, discountCards])

  const buttonsDisabled = useMemo(
    () => (!isAnyCardApplied && (!hasAnyValue || !valueChanged)) || !!error.PROMOCODE,
    [isAnyCardApplied, hasAnyValue, valueChanged, error.PROMOCODE],
  )

  useEffect(() => {
    if (isCardInvalid && !isVacancyLoading && !valueChanged && inputCards) {
      const firstTruthyKey = Object.entries(inputCards).find(([_, value]) => Boolean(value))?.[0]

      firstTruthyKey && setError({ [firstTruthyKey]: t('checkout.discounts.invalidCodeError') })
    } else {
      setError({})
    }
    // Reason: Rely on vacancy change to avoid multiple re-renders and error message flickering
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vacancy, setError, isVacancyLoading])

  const removeCards = (): void => {
    discountFields?.forEach(fieldName => {
      setFieldValue(`cards.${fieldName}`, '')
    })
    setFieldValue('meta.cards', [])
    setFieldValue('vacancies', [])
    setParams({ cards: [] })
  }

  const applyCards = (): void => {
    setFieldValue('meta.cards', checkout.getCardsParams(inputCards))
    setFieldValue('vacancies', [])
    setParams({ cards: checkout.getCardsParams(inputCards) })
  }

  const handlePromocodeValidation = (code: string): void => {
    !PROMOCODE_REGEX.test(code) && setError({ PROMOCODE: t('checkout.discounts.invalidCodeError') })
  }

  const onChange = (code: string, name: string): void => {
    setValueChanged(true)
    setError({})
    name === 'PROMOCODE' && handlePromocodeValidation(code)
  }

  const onClick = (): void => {
    isAnyCardApplied ? removeCards() : applyCards()

    setValueChanged(false)
  }

  return (
    <div>
      <h3 className="mb-5">{t('checkout.discounts.title')}</h3>
      <Skeleton.List Skeleton={DiscountsSkeleton} loading={isLoading} amount={1}>
        <>
          <div className="row wrap gap-3 column-sm">
            {discountCards?.map(card => (
              <div key={card.name} className="cell-6 cell-sm-12">
                <Field
                  component={InputField}
                  icon={<Icon name={discount.getIcon(card.name)} size="small" />}
                  iconSecondary={isCardTypeApplied(card.name) && <CodeAppliedIcon />}
                  label={t(`searchBar.discounts.${card.name}`)}
                  name={`cards.${card.name}`}
                  validate={uniq(cards)}
                  disabled={isAnyCardApplied || hasValue(card.name)}
                  errorMessage={error[card.name]}
                  onChange={(code: string) => onChange(code, card.name)}
                />
              </div>
            ))}
          </div>
          <div className="row mt-5 column-sm">
            <Button color="secondary" variant="text" onClick={onClick} disabled={buttonsDisabled}>
              <span>{t(`checkout.discounts.${isAnyCardApplied ? 'removeCode' : 'applyCode'}`)}</span>
            </Button>
          </div>
        </>
      </Skeleton.List>
    </div>
  )
}

export default Discounts

import cn from 'classnames'
import React, { ReactElement } from 'react'

import bem from '@lib/bem'

import '@pages/SearchResult/WeeklyList/Item/Cell.scss'

interface CellProps {
  className?: string
  children: React.ReactNode
  header?: boolean
  removeBorders?: boolean
}

const Cell = ({ className, children, header, removeBorders }: CellProps): ReactElement => (
  <div
    className={cn(
      bem('weekly-list', 'cell', {
        header,
        ['no-borders']: removeBorders,
      }),
      'row center items-center h-100',
      className,
    )}
  >
    {children}
  </div>
)

export default Cell

import cn from 'classnames'
import { useFormikContext } from 'formik'
import React, { useState } from 'react'

import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import InvoiceForm from '@pages/Checkout/Form/Invoice/Form'
import Preview from '@pages/Checkout/Form/Invoice/Mobile/Preview'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { Button, Modal } from '@ui'

const InvoiceMobile = () => {
  const [opened, setOpened] = useState(false)
  const [isComplete, setIsComplete] = useState(false)
  const { t } = useTranslation()
  const { values, setFieldValue, validateForm, setFieldTouched } = useFormikContext<CheckoutFormData>()

  const handleOpen = (): void => {
    !isComplete && setFieldValue('invoice.type', 'company', false)
    setOpened(true)
  }

  const handleClose = (): void => {
    !isComplete && setFieldValue('invoice', null, false)
    setOpened(false)
  }

  const handleSave = async (): Promise<void> => {
    await validateForm().then(({ invoice }) => {
      if (!invoice) {
        setOpened(false)
        setIsComplete(true)
      } else Object.keys(invoice).forEach(key => setFieldTouched(`invoice.${key}`))
    })
  }

  const handleRemove = async (): Promise<void> => {
    setFieldValue('invoice', null, false)
    setOpened(false)
    setIsComplete(false)
  }

  return (
    <div className={bem('checkout', 'section')}>
      <div className={cn({ column: isComplete, row: !isComplete }, 'space-between')}>
        <h3 className="cell no-grow m-0">{t('checkout.invoice.title')}</h3>
        <div className="cell no-grow">
          {!isComplete && (
            <Button variant="text" onClick={handleOpen}>
              {t('checkout.invoice.request')}
            </Button>
          )}
          {isComplete && values.invoice && <Preview invoice={values.invoice} onClick={() => setOpened(true)} />}
          <Modal opened={opened} onClose={handleClose} title={t('checkout.invoice.title')}>
            <div className="px-4 py-2 column gap-3 invoice__modal">
              <InvoiceForm />
              {isComplete && (
                <div className="invoice__modal-remove">
                  <Button variant="text" onClick={handleRemove}>
                    {t('checkout.invoice.remove')}
                  </Button>
                </div>
              )}
              <div className="px-4 py-2 invoice__modal-save">
                <Button onClick={handleSave}>{t('checkout.invoice.save')}</Button>
              </div>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default InvoiceMobile

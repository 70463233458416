import { isAfter, isBefore, isValid } from 'date-fns'
import { t } from 'i18next'
import { postcodeValidator } from 'postcode-validator'

import config from '@config'
import dateUtils from '@lib/date'
import validationUtils from '@lib/validation'

export * from '@lib/validators/phoneNumber'

export const chain =
  (validators: Validator[]): Validator =>
  (value: any) => {
    for (const validate of validators) {
      const error = validate(value)

      if (error) return error
    }
  }

export const required: Validator = value => {
  const emptyValues = [undefined, null, '', false]
  const isError = emptyValues.includes(value)

  return isError ? t('errors.required') : undefined
}

export const optionSelectedFactory =
  <T>(text: string, getOptionText: (option: T) => string | null | undefined): Validator =>
  value =>
    getOptionText(value) !== text ? t('errors.autocomplete.selectOption') : undefined

export const email: Validator = value => {
  const isMatched = (value as string).match(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/)

  return isMatched ? undefined : t('errors.invalid_email')
}

export const date: Validator = value => {
  const minDate = dateUtils.parse('1900-01-01')
  const date = dateUtils.parse(value)

  return !isValid(date) || isBefore(date, minDate) ? t('errors.invalid_date') : undefined
}

export const birthDate: Validator = value => {
  if (required(value)) return required(value)

  const dateValue = dateUtils.parse(value)

  return date(value) || isAfter(dateValue, new Date()) ? t('errors.invalid_date') : undefined
}

const getPaxCount = (value: Passenger.Param[], types?: (string | undefined)[]): number =>
  value.reduce((mem, curr) => (types?.includes(curr.type) ? mem + curr.pax : mem), 0)
export const passengerType = (value: Passenger.Param[], rpn: number): string | undefined => {
  const { required, check, relation } = { ...config.passengersValidity.byRpn[rpn] }

  if (!relation) return

  // eslint-disable-next-line no-unsafe-optional-chaining
  const [multiplier, index] = relation?.split(':')
  const requiredCount = getPaxCount(value, required)
  const checkCount = getPaxCount(value, check)

  const underage =
    checkCount * Number(index) > requiredCount * Number(multiplier) ||
    (checkCount > Number(index) && requiredCount < Number(multiplier))

  return underage ? t('errors.underage', { count: Number(index) }) : undefined
}

export const zipCode = (countryCode?: string | null) => (value?: string) => {
  const shouldValidate = value != null && countryCode != null && ['US', 'CA'].includes(countryCode)

  if (shouldValidate && !postcodeValidator(value, countryCode)) {
    return t('errors.zipCode')
  }
}

export const uniq =
  <T>(anotherValue?: T | null): Validator<string | null> =>
  value => {
    const compare = anotherValue && Object.values(anotherValue).filter(Boolean).length >= 2
    if (value && compare) return t('errors.uniq')
  }

export const exact =
  (otherValue: string | null, message: string): Validator =>
  value => {
    if (value !== otherValue) return message
  }

export const vehicle: Validator = (value: Ancillary.Item[]) => {
  const isError = !value.length

  return isError ? t('errors.vehicle') : undefined
}

export const meal = (count: number) => (value: Ancillary.Item[]) => {
  const isError = value.length !== count

  return isError ? t('errors.meal') : undefined
}

export const VAT = (country?: string | null) => (value: string) => {
  if (!country) return t('errors.invalidCountry')

  const regex = validationUtils.getVATRegex(country)
  if (!regex) return undefined

  const isMatched = regex.test(value)

  return isMatched ? undefined : t('errors.invalidVat')
}

export const fiscalCode = (value: string) => {
  const regex = validationUtils.getFiscalCodeRegex()
  const isMatched = regex.test(value)

  return isMatched ? undefined : t('errors.invalidFiscalCode')
}

export const length =
  (length: number, message: string): Validator =>
  value => {
    if (value.length !== length) return message
  }

import cn from 'classnames'
import React, { ReactElement } from 'react'

import useIsMobile from '@hooks/useIsMobile'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { Icon, Tooltip } from '@ui'

interface CopyPasteProps {
  disabled: boolean
  isTimeCopied: boolean
  onCopy: () => void
  onPaste: () => void
}

const CopyPaste = ({ onCopy, onPaste, disabled, isTimeCopied }: CopyPasteProps): ReactElement => {
  const { t } = useTranslation()
  const isMobile = useIsMobile()

  const showPaste = isTimeCopied && disabled
  const showCopy = !disabled || (!showPaste && disabled)
  const key = showCopy ? 'copyTimeTooltip' : 'pasteTimeTooltip'

  return (
    <Tooltip content={t(`searchResult.weeklyTrip.${key}`)} action="hover" position="top">
      {showCopy && (
        <span className={cn(bem('weekly-list', 'copy', { disabled }), 'row')} onClick={onCopy}>
          <Icon name="copy" size="small" className="mr-1" />
          {isMobile && <span>{t('searchResult.weeklyTrip.copyTime')}</span>}
        </span>
      )}
      {showPaste && (
        <span className="weekly-list__copy row" onClick={onPaste}>
          <Icon name="checkout" size="small" className="mr-1" />
          {isMobile && <span>{t('searchResult.weeklyTrip.pasteTime')}</span>}
        </span>
      )}
    </Tooltip>
  )
}

export default CopyPaste

import React, { useCallback, useMemo } from 'react'

import { PaymentMethodStatus } from '@enums'
import { SubmitEventPayload } from '@hooks/useBookingFlow'
import utils from '@lib/utils'
import { PaymentMethod } from '@pages/Checkout/hooks/Payment/useBookingPayment'
import PaymentLabel from '@pages/Checkout/Payment/Label'

interface Props {
  type: PaymentMethodType
}

const useDelayedPayment = ({ type }: Props): PaymentMethod => {
  const getOption = useCallback(
    () => ({
      value: type,
      label: <PaymentLabel type={utils.string.toCamelcase(type)} showName />,
    }),
    [type],
  )

  const submitForm = useCallback(
    (): SubmitEventPayload => ({ paymentProvider: 'delayed', paymentMethodData: { type } }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  return useMemo(
    () => ({
      status: PaymentMethodStatus.Ready,
      getOption,
      on: {
        submitForm,
      },
    }),
    [getOption, submitForm],
  )
}

export default useDelayedPayment

import { Field } from 'formik'
import React, { ReactElement, useMemo } from 'react'

import config from '@config'
import sortUtils from '@lib/sorting'
import { useCountries } from '@loaders/countries'
import { useParams } from '@stores/params'
import DropdownField from '@ui/Dropdown/Field'

const { sortBy } = sortUtils

interface CountryFieldProps {
  name: string
  label: string
  validate?: Validator
  required?: boolean
}

const Index = (props: CountryFieldProps): ReactElement => {
  const [{ locale }] = useParams()
  const { data: countries } = useCountries({ locale })
  const options = useMemo(
    () =>
      sortBy(countries ?? [], 'name').map(({ code, name }) => ({
        label: name,
        value: code,
        searchValue: name,
      })),
    [countries],
  )

  return (
    <Field
      component={DropdownField}
      searchable
      pinnedValues={config.settings.topCountries}
      items={options}
      {...props}
    />
  )
}

export default Index

import { useMemo } from 'react'

import { useSettings } from '@queries/settings'

interface UseWeeklyHook {
  disabled: boolean
  requiredTrips: number
  selectedTripsCount: number
}

const getDepartureDates = (connections: (Connection | null)[][]): string[] =>
  connections?.reduce<string[]>((acc, curr) => {
    curr.forEach(connection => connection && acc.push(connection.departureTime.split('T')[0]))

    return acc
  }, [])

const useWeekly = (selected: Record<string, (Connection | null)[]>): UseWeeklyHook => {
  const [{ weeklyTrip }] = useSettings()

  const { requiredDays, returnTrip } = weeklyTrip

  const requiredTrips = useMemo(() => requiredDays * (Number(returnTrip) + 1), [requiredDays, returnTrip])
  const departureDates = useMemo(() => getDepartureDates(Object.values(selected)), [selected])

  const disabled = useMemo(
    () => [...new Set(departureDates)].length !== requiredDays || departureDates.length !== requiredTrips,
    [departureDates, requiredDays, requiredTrips],
  )

  return {
    disabled,
    requiredTrips,
    selectedTripsCount: departureDates.length,
  }
}

export default useWeekly

import { PaymentAction } from '@adyen/adyen-web/types'

import { BookingPassenger } from '@api/booking'
import makeApi from '@lib/api'

export interface PaymentPlatform {
  platformType?: string
  osType?: string
  appId?: string
}

interface RequestBody {
  bookingFormId: number
  paymentMethod: PaymentMethodType | null
  passengers: BookingPassenger[]
  termsAccepted: boolean
  paymentMethodData?: object
  paymentProvider: string
  discountCode?: string
  email?: string
  phone?: string
  sendMarketingEmails?: boolean
  cardHolderName?: string | null
  sourceId?: string
  markupFee?: number
  paymentIntentId?: string
  express?: boolean
  paymentAttemptReference?: string
  profilingReference?: string
  numberOfInstallments?: number
  cpf?: string | null
  cardData?: object
  browserInfo?: object
  termsVersion?: string | null
  streetAndNumber?: string | null
  city?: string | null
  countryCode?: string | null
  state?: string | null
  zipCode?: string | null
  firstName?: string
  lastName?: string
  deviceFingerprint?: string | null
  retailerBookingNumber?: string | null
  offerId?: number | null
  platform?: PaymentPlatform
  shopperReference?: string | null
  paymentToken?: string | null
  businessName?: string | null
  taxId?: string | null
  vatNumber?: string | null
  utmParams?: Record<string, string> | null
  customFields?: object
}

export interface Response {
  action: BookingAction
  bookingFormId: number
  redirectPath: string
  reservationStatus: string
  details: {
    action: PaymentAction
  }
  reservationId: number | null
}

export interface Request extends RequestBody {
  id: number
}

export const confirm = async (params: Request): Promise<Response> => {
  const { id, ...body } = params

  const url = `/reservations/${id}/confirm`
  const callApi = makeApi.post<{}, RequestBody, Response>({
    type: 'reservations',
    old: url,
    new: url,
  })

  return await callApi({ body, params: {} })
}

import React from 'react'

import { IndividualInvoice, InvoiceData } from '@pages/Checkout/hooks/useInitialFormValues'
import { Icon } from '@ui'

interface PreviewProps {
  invoice: InvoiceData
  onClick: () => void
}

const isIndividual = (invoice: InvoiceData): invoice is IndividualInvoice => invoice.type === 'individual'

const getInvoiceInfo = (invoice: InvoiceData): Record<string, string> => ({
  title: isIndividual(invoice) ? `${invoice.firstName} ${invoice.lastName}` : invoice.businessName,
  code: isIndividual(invoice) ? invoice.fiscalCode : invoice.taxCode,
})

const Preview = ({ invoice, onClick }: PreviewProps) => {
  const { address, country, zipCode, city, state } = invoice
  const { title, code } = getInvoiceInfo(invoice)

  return (
    <div className="invoice__preview column px-2 py-3 mt-3">
      <div className="row space-between">
        <h4 className="mb-1 mr-1">{title}</h4>
        <Icon name="edit" size="medium" onClick={onClick} />
      </div>
      <span className="invoice__preview-code body-14 mb-1">{code}</span>
      <span className="body-14">{address}</span>
      <span className="body-14">{`${zipCode} ${city}, ${state}`}</span>
      <span className="body-14">{country}</span>
    </div>
  )
}

export default Preview

import React, { ReactElement } from 'react'

import carrierUtils from '@lib/carrier'
import { useTranslation } from '@lib/i18n'
import weeklyUtils from '@lib/weekly'
import ConnectionBreakdown, { ConnectionBreakdownData } from '@pages/Checkout/BookingDetails/ConnectionBreakdown'
import useValidity from '@pages/Checkout/hooks/useValidity'
import { useParams } from '@stores/params'

import '@pages/Checkout/BookingDetails/TripBreakdown/index.scss'

interface TripBreakdownProps {
  express?: boolean
  outbound: ConnectionBreakdownData | null
  inbound: ConnectionBreakdownData | null
  carriers?: MarketingCarrier[]
  isCarriersLoading: boolean
  fareClass?: string
  weeklyConnections?: WeeklyConnectionsParams
}

const TripBreakdown = (props: TripBreakdownProps): ReactElement => {
  const { express, outbound, inbound, carriers = [], isCarriersLoading, fareClass, weeklyConnections } = props
  const { t } = useTranslation()
  const [{ hideLocations }] = useParams()
  const isTwoWayTrip = !!inbound
  const weeklyTripsCount = weeklyUtils.getConnectionsCount(weeklyConnections)
  const weeklyTripsDates = weeklyUtils.getDatesRange(weeklyConnections)
  const connectionBreakdownProps = {
    showTime: !express && !weeklyTripsCount,
    showCarrier: !express && !!carriers.length,
    showDuration: express,
    hideLocations,
  }
  const { outboundValidity, inboundValidity } = useValidity({ fareClass, carriers, inbound, outbound })

  const getTitle = (): string | null => {
    if (weeklyTripsCount) return t('checkout.bookingDetails.weeklyTrip', { count: weeklyTripsCount })
    if (isTwoWayTrip) return t('checkout.bookingDetails.outboundTrip')

    return null
  }

  return (
    <div className="checkout-trip">
      {outbound && (
        <ConnectionBreakdown
          {...connectionBreakdownProps}
          connection={outbound}
          carriers={carrierUtils.getCarriers(outbound, carriers)}
          title={getTitle()}
          fareClass={fareClass}
          validity={outboundValidity}
          isLoading={isCarriersLoading}
          weeklyDates={weeklyTripsDates}
        />
      )}
      {inbound && !weeklyTripsCount && (
        <>
          <hr />
          <ConnectionBreakdown
            {...connectionBreakdownProps}
            connection={inbound}
            carriers={carrierUtils.getCarriers(inbound, carriers)}
            title={t('checkout.bookingDetails.returnTrip')}
            fareClass={fareClass}
            validity={inboundValidity}
            isLoading={isCarriersLoading}
          />
        </>
      )}
    </div>
  )
}

export default TripBreakdown

import { PaymentAction } from '@adyen/adyen-web/types'

import { BookingAncillaries, BookingPassenger, BookingStatusResponse } from '@api/booking'
import { NormalRequest } from '@api/reservation/create'
import { Request, Response } from '@api/reservation/finalize'
import { ConfirmPaymentParams } from '@hooks/useBookingFlow'
import makeApi, { ResponsePostprocessing } from '@lib/api'

export type AmendmentConditionsResponse = Amendment.Conditions

export interface AmendmentConditionsRequest {
  retailerPartnerNumber: RetailerNumber
}

export interface AmendmentCreateRequest extends Omit<NormalRequest, 'passengers'> {
  bookingId: string
  ancillaries?: BookingAncillaries[]
  passengers?: BookingPassenger[]
}

export interface AmendmentCreateResponse {
  id: string
  price: Money
  deltaPrice: Money
  bookingFormId: number
}

export interface AmendmentConfirmRequest {
  retailerPartnerNumber: RetailerNumber
  termsAccepted: boolean
  paymentMethod?: PaymentMethodType | null
  paymentMethodData?: object
  paymentProvider?: string
  cardHolderName?: string | null
  numberOfInstallments?: number
  cpf?: string | null
  cardData?: object
  browserInfo?: object
  streetAndNumber?: string | null
  city?: string | null
  countryCode?: string | null
  state?: string | null
  zipCode?: string | null
  firstName?: string
  lastName?: string
  deviceFingerprint?: string | null
}

export type AmendmentStatusResponse = BookingStatusResponse

export interface AmendmentFinalizeRequest {
  paymentDetails: {
    details: {
      threeDSResult: string
    }
  }
}

export interface AmendmentFinalizeResponse {
  action: BookingAction
  bookingFormId: number
  redirectPath: string
  bookingStatus: string
  details: {
    action: PaymentAction
  }
}

const config: ResponsePostprocessing = {
  convertValues: {
    fee: 'Price',
  },
}

export default {
  conditions: async (id: string, params: AmendmentConditionsRequest) => {
    const callApi = makeApi.get<AmendmentConditionsRequest, AmendmentConditionsResponse>(`/booking/${id}/conditions`, {
      responsePostprocessing: config,
    })

    return await callApi(params)
  },
  create: makeApi.post<{}, AmendmentCreateRequest, AmendmentCreateResponse>({
    type: 'amendments',
    old: '/amendments',
    new: '/amendments',
  }),
  confirm: async (id: string, body: AmendmentConfirmRequest) => {
    const callApi = makeApi.post<{}, AmendmentConfirmRequest, ConfirmPaymentParams>({
      type: 'amendments',
      old: `/amendments/${id}/confirm`,
      new: `/amendments/${id}/confirm`,
    })

    return await callApi({ body, params: {} })
  },
  status: async (id: number): Promise<AmendmentStatusResponse> => {
    const callApi = makeApi.get<{}, AmendmentStatusResponse>(`/amendments/${id}/status`)

    return await callApi({})
  },
  finalize: async (bookingFormId: number, body: AmendmentFinalizeRequest): Promise<AmendmentFinalizeResponse> => {
    const callApi = makeApi.post<{}, Request, Response>(`/amendments/${bookingFormId}/details`)

    return await callApi({ body, params: {} })
  },
}

import { Field, useFormikContext } from 'formik'
import React, { useCallback, useContext, useEffect, useState } from 'react'

import ancillaryUtils from '@lib/ancillary'
import bem from '@lib/bem'
import { useTranslation } from '@lib/i18n'
import { required } from '@lib/validators'
import AncillarySelect, { AncillaryFieldData } from '@pages/Checkout/Extras/Ancillary/components/Controls/Select'
import InlineAncillary from '@pages/Checkout/Extras/Ancillary/components/Inline'
import AncillaryContext from '@pages/Checkout/Extras/Ancillary/Context'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import ErrorMessage from '@ui/ErrorMessage'
import InputField from '@ui/Input/Field'

import '@pages/Checkout/Extras/Ancillary/Insurance.scss'

const PassengerFields = () => {
  const { values } = useFormikContext<CheckoutFormData>()
  const { passengers } = values
  const { ancillary } = useContext(AncillaryContext)
  const { t } = useTranslation()

  return (
    <div className="column gap-4">
      {passengers.map(({ firstName, lastName, id }, index) => (
        <div key={id} className="cell">
          <div className="row column-sm insurance__passenger gap-3">
            <div className="cell-4 cell-sm-12">
              <div className="column">
                <div className="body-12 insurance__passenger-label">
                  {t('checkout.passengerDetails.passengerLabel', { replace: { index: index + 1 } })}
                </div>
                <div className="body-16">
                  {firstName} {lastName}
                </div>
              </div>
            </div>
            {ancillary.customAttributes.map(({ id, key }, attributeIndex) => (
              <div key={id} className="cell-4 cell-sm-12">
                <Field
                  component={InputField}
                  label={t(`extras.ancillary.insurance.${key}`)}
                  name={`ancillaries.${ancillary.category}.${index}.customAttributes.${attributeIndex}.value`}
                  validate={required}
                  updateEvent="onBlur"
                  required
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  )
}

const InsuranceAncillary = () => {
  const { t } = useTranslation()
  const { values, errors, touched } = useFormikContext<CheckoutFormData>()
  const { ancillaries, passengers } = values
  const [error, setError] = useState<string>()

  const validateEmail = useCallback(() => {
    if (errors.contact?.email || !touched.contact?.email) {
      return t('extras.ancillary.insurance.missingEmail')
    }
  }, [errors.contact?.email, t, touched.contact?.email])

  useEffect(() => {
    if (error) {
      setError(validateEmail())
    }
  }, [ancillaries.INSURANCE.length, error, validateEmail])

  const onToggle = useCallback(
    (item: Ancillary.Item | null, [, , { setTouched }]: AncillaryFieldData) => {
      if (!item) {
        setError(undefined)
        return
      }

      const error = validateEmail()

      if (error) {
        setError(error)

        return false
      } else {
        setTouched(false)
      }
    },
    [validateEmail],
  )

  return (
    <InlineAncillary
      name="INSURANCE"
      classnames="insurance"
      pricePerPassenger={passengers.length > 1}
      controls={<AncillarySelect onToggle={onToggle} override={ancillaryUtils.clearAttributes} />}
    >
      {typeof error === 'string' && <ErrorMessage className={bem('insurance', 'error')} fallbackMessage={error} />}
      {ancillaries.INSURANCE.length > 0 && (
        <div className="mt-2">
          <PassengerFields />
        </div>
      )}
    </InlineAncillary>
  )
}

export default InsuranceAncillary

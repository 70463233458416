import { useFormikContext } from 'formik'
import React, { useCallback } from 'react'

import { useTranslation } from '@lib/i18n'
import InvoiceForm from '@pages/Checkout/Form/Invoice/Form'
import { CheckoutFormData } from '@pages/Checkout/hooks/useInitialFormValues'
import { Accordion } from '@ui'
import Switch from '@ui/Switch'

const InvoiceDesktop = () => {
  const { values, setFieldValue } = useFormikContext<CheckoutFormData>()
  const { t } = useTranslation()
  const onInvoiceStateChange = useCallback(
    (state: boolean) => {
      setFieldValue('invoice', state ? { type: 'company' } : undefined, false)
    },
    [setFieldValue],
  )

  return (
    <Accordion
      opened={values.invoice !== undefined}
      className="invoice"
      title={t('checkout.invoice.title')}
      divider={false}
      onChange={onInvoiceStateChange}
      renderIcon={opened => <Switch checked={opened} />}
    >
      <InvoiceForm />
    </Accordion>
  )
}

export default InvoiceDesktop

import { Field } from 'formik'
import React from 'react'

import PhoneField from '@components/PhoneField'
import { useTranslation } from '@lib/i18n'
import { chain, email, phoneNumber, required } from '@lib/validators'
import InputField from '@ui/Input/Field'

const GeneralInformation = () => {
  const { t } = useTranslation()

  return (
    <div className="row gap-3 wrap">
      <div className="cell-6 cell-sm-12">
        <Field
          component={InputField}
          label={t('customBlocksCommon.form.firstName')}
          name="firstName"
          validate={required}
          required
        />
      </div>
      <div className="cell-6 cell-sm-12">
        <Field
          component={InputField}
          label={t('customBlocksCommon.form.lastName')}
          name="lastName"
          validate={required}
          required
        />
      </div>
      <div className="cell-6 cell-sm-12">
        <Field
          component={InputField}
          label={t('customBlocksCommon.form.email')}
          name="email"
          validate={chain([required, email])}
          required
        />
      </div>
      <div className="cell-6 cell-sm-12">
        <Field
          component={PhoneField}
          label={t('customBlocksCommon.form.phone')}
          name="phone"
          validate={chain([required, phoneNumber])}
          required
        />
      </div>
    </div>
  )
}

export default GeneralInformation
